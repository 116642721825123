.founderContainer {
  width: 100%;
  padding-top: 9.479vw;
  padding-bottom: 6.92vw;
  background-color: black;
}
.founder-avatars-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
.ourFounder {
  text-align: center;
  font-size: 4vw;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 6.4vw;
}
.LeftAvatar {
  padding-left: 2.6vw;
  padding-top: 4.68vw;
}
.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}
.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}

.ConfrimImage {
  display: flex;
  margin-bottom: 35px;
  align-items: center;
}
.ConfrimImage img {
  background-color: #eff6f5;
  border-radius: 50%;
  margin-inline-end: 50px;
  width: 14.5vw;
  height: 14.5vw;
}
.YourCompanyClientArea {
  text-align: left;
  font: normal normal medium 25px/38px Poppins;
  letter-spacing: 0px;
  color: rgba(23, 23, 23, 1);
  opacity: 1;
  width: 22vw;
}
.BackToPersonalisationButton {
  width: 37vw;
}

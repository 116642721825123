.TeamMemberContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 0 4vw;
}
.MemberName {
  text-align: left;
  font-size: 1.25vw;
  letter-spacing: 0.36px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-bottom: 1.3vw;
  margin-top: 3vw;
}
.MemberTitle {
  text-align: left;
  font-size: 3.1vw;
  font-weight: 700;
  font-family: "FreightBig Pro";
  margin-bottom: 1vw;
  line-height: 3.2vw;
  white-space: pre-line;
}
.MemberExperience {
  text-align: left;
  letter-spacing: 0.23px;
  color: #2a2a2a;
  opacity: 0.8;
  font-size: 1.19vw;
  white-space: pre-line;
}
.TeamMemberImage {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
}
.TeamMemberImage img {
  width: 100%;
}
.TeamMemberDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-inline-end: 3vw;
}

.ReactModal__Overlay {
  z-index: 2000;
}

@media (max-width: 768px) {
  .TeamMemberContainer {
    flex-direction: column;
    padding: 0 2vw;
  }
  .MemberTitle {
    white-space: normal;
    font-size: 17px;
    line-height: 4vw;
  }
  .MemberName {
    font-size: 9px;
  }
  .MemberExperience {
    white-space: normal;
    font-size: 8px;
    margin: 10px 0;
  }
}

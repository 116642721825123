/* CSS Document */
.GetNotifiedContainer .dropdown {
  cursor: pointer;
  width: 100%;
  height: 55px;
  border-radius: 2px;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e4e3;
}

.NotifyMe {
  margin-top: 15px;
  width: 100%;
  height: 50px;
  color: #ffffff;
  background: #2f53eb 0% 0% no-repeat padding-box;
  border: #2f53eb;
  border-radius: 2px;
  opacity: 1;
}
.GetNotifiedForm {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.GetNotifiedContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e4e3;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45vw;
  margin-top: 30px;
  padding: 10vw 7vw;
}
.GetNotifiedFormFields {
  margin-top: 50px;
}
.inputLabelGetNotified {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 9px;
}
.GetNotifiedContainer .GetNotifiedFormFields form .jobForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.GetNotifiedContainer
  .GetNotifiedFormFields
  form
  .jobForm
  .inputLabelGetNotified,
.GetNotifiedContainer .GetNotifiedFormFields form .jobForm .inputControl,
.GetNotifiedContainer .GetNotifiedFormFields form .jobForm .jobSelection {
  width: 100%;
}
.GetNotifiedContainer img {
  width: 75px;
  height: 75px;
}

@media (max-width: 768px) {
  .GetNotifiedContainer {
    width: 90vw;
  }
  .GetNotifiedContainer .GetNotifiedForm {
    align-items: center;
  }
  .GetNotifiedContainer .GetNotifiedForm h2 {
    font-size: 34px;
  }
  .GetNotifiedContainer img {
    width: 50px;
    height: 50px;
  }
}

/* .establishedContainer {
  background-color: #fff;
  width: 100%;
  height: 809px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  margin: auto;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}
.establishedIn {
  text-align: left;
  font: normal normal bold 78px/88px Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.Year2021 {
  text-align: left;
  font: normal normal bold 184px/208px Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.establishedDescription {
  text-align: left;
  font: normal normal normal 26px/33px Forma DJR Micro;
  letter-spacing: 0.26px;
  color: #414141;
  opacity: 1;
  width: 577px;
}
.RightEstablished {
  width: 83.9vw;
  position: absolute;
  top: -36.5vw;
  inset-inline-end: -56vw;
}
.rightSection {
  position: relative;

  width: 100%;
  height: 100px;
}

.translate-rotate {
  transform: translateX(180px) rotate(45deg);
}
.LeftEstablished {
  width: 83.9vw;
  position: absolute;
  top: 31.5vw;
  inset-inline-start: -54vw;
}
.LeftEstablished {
  width: 83.9vw;
  position: absolute;
  top: 52.5vw;
  inset-inline-end: -45vw;
} */

.establishedContainer {
  padding-top: 18.8vw;
  padding-bottom: 33.125vw;
  background: white;
  position: relative;
  overflow: hidden;
}
.establishedContainer .img1 {
  display: block;
  position: absolute;
  top: -12vw;
  inset-inline-start: -49vw;
  width: 78vw;
}
.establishedContainer .img2 {
  display: block;
  position: absolute;
  top: -19vw;
  inset-inline-end: -42vw;
  width: 78.3vw;
}
.establishedContainer .img3 {
  display: block;
  position: absolute;
  bottom: -42vw;
  inset-inline-start: 9vw;
  width: 70.2vw;
}

.establishedContainer .Established {
  padding-inline-start: 31.875vw;
}
.establishedContainer .Established .establishedIn {
  font-weight: 700;
  font-size: 4vw;
}
.establishedContainer .Established .Year2021 {
  font-weight: 700;
  font-size: 9.5vw;
  margin-bottom: 0.8vw;
}
.establishedContainer .Established .establishedDescription {
  white-space: pre-line;
  color: rgba(65, 65, 65, 1);
  font-size: 1.354vw;
}

@media (max-width: 768px) {
  .establishedContainer .Established .establishedDescription {
    font-size: 8px;
  }
  .establishedContainer .img2 {
    inset-inline-end: -56vw;
  }
}

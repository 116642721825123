.maximizeContainer {
  background: #ffffff;
  opacity: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 50%;
  width: 52px;
  height: 52px;
}
.minimizeIcon {
  width: 17px;
  height: 17px;
  filter: invert(1);
}

@media (max-width: 768px) {
  .minimizeComponent {
    width: 20px;
  }
  .maximizeContainer {
    width: 20px;
    height: 20px;
  }
  .maximizeContainer img {
    width: 9px;
    height: 9px;
  }
}

.spfHero {
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8.4vw;
  margin-bottom: 14vw;
}

.spfHero small {
  font-size: 0.93vw;
  color: white;
  margin-bottom: 0.6vw;
}

.spfHero h1 {
  font-size: 3.43vw;
  color: white;
  font-weight: 700;
  margin-bottom: 0.6vw;
  white-space: pre-line;
  text-align: center;
  margin-bottom: 4.1vw;
  font-family: "FreightBig Pro";
}

.spfHero img {
  width: 70vw;
  margin-bottom: -8vw;
}

@media (max-width: 768px) {
  .spfHero small {
    font-size: 12px;
  }

  .spfHero h1 {
    font-size: 28px;
  }
}

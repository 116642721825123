.careerValuesContainer {
  margin-top: 80px;
}
.careerValues {
  margin: 0 !important;
}
.availabeCareers {
  margin-top: 120px;
  width: 100%;
  text-align: start;
}
.slogan {
  width: 50%;
  margin: 0 auto;
}
.slogan span {
  font-size: 14px;
}
.slogan p {
  margin-top: 10px;
  font-size: 2.34vw;
  font-weight: bold;
  color: #000;
  opacity: 1;
}
.jobs {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}
.jobsCard {
  width: 22.31vw;
  height: 34.01vw;
  /* padding: 1px !important; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}
.jobsCard img {
  max-width: 100%;
  height: auto;
}
.jobPostion {
  text-align: center;
  height: 10%;
}
.more {
  height: 88%;
  position: relative;
}
.more-maximize {
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 1;
}
.positionTitle {
  text-align: center;
  font: normal normal bold 1.77vw/3.69vw FreightBig Pro;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.positionDescription {
  text-align: center;
  font: normal normal medium 14px/33px Forma DJR Micro;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.8;
}

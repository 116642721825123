.fintoldFooter {
  background: white;
  margin-top: 119px;
  max-width: 1225px;
  margin-left: auto;
  margin-right: auto;
}

.fintoldFooter .footerContent {
  display: flex;
  align-items: flex-start;
  padding-bottom: 102px;
  border-bottom: 1px solid #707070;
  padding-inline-start: 30px;
}
.fintoldFooter .footerContent .column {
  margin-inline-end: 182px;
}
.fintoldFooter .footerContent .column:first-child {
  margin-inline-end: 109px;
}
.fintoldFooter .footerContent .column:last-child {
  margin-inline-end: 0px;
}
.fintoldFooter .footerContent .column h6 {
  color: #000000;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  top: 20px;
  margin-bottom: 43px;
}

.fintoldFooter .footerContent .column ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.fintoldFooter .footerContent .column li {
  margin-bottom: 7px;
}
.fintoldFooter .footerContent .column li a.disabled {
  color: black;
  opacity: 0.4;
  text-decoration: none;
  cursor: default;
}
.fintoldFooter .footerContent .column li a {
  cursor: pointer;
  opacity: 1;
  color: black;
  text-decoration: none;
}
.fintoldFooter .footerContent .column li a.loginLink {
  font-weight: bold;
}
.fintoldFooter .footerContent .column li a.signupLink {
  cursor: pointer;
  opacity: 1;
  color: white;
  text-decoration: none;
  background: black;
  border-radius: 3px;
  padding: 0.67vw 1.92vw;
  margin-bottom: 1.3vw;
  display: block;
}
.fintoldFooter .policyAndTerms {
  margin-top: 23px;
  padding-bottom: 20px;
}
.fintoldFooter .policyAndTerms p {
  color: #7a8281;
  font-size: 14px;
  margin-bottom: 10px;
}
.fintoldFooter .policyAndTerms .tplinks {
  display: flex;
  align-items: center;
}
.fintoldFooter .policyAndTerms .tplinks a {
  margin-inline-end: 20px;
  color: #7a8281;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .fintoldFooter .footerContent {
    flex-direction: column;
  }
  .fintoldFooter .policyAndTerms {
    padding: 0 30px 20px 30px;
  }
}

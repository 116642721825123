* {
    font-family: "GE SS Two";
  }
  
  .latinText {
    direction: ltr;
    display: inline-block;
  }
  
  @font-face {
    font-family: "GE SS Two";
    font-style: Light;
    font-weight: 300;
    src: local(""), url("./fonts/GE_SS_Two_Light.woff") format("woff"); /* Modern Browsers */
  }
  
  @font-face {
    font-family: "GE SS Two";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("./fonts/GE_SS_Two_Medium.woff") format("woff"); /* Modern Browsers */
  }
  
  @font-face {
    font-family: "GE SS Two";
    font-style: Bold;
    font-weight: 800;
    src: local(""), url("./fonts/GE_SS_Two_Bold.woff") format("woff"); /* Modern Browsers */
  }
  
.forgotPasswordsaas .confirmTabWebsaas {
  display: flex;
  margin-bottom: 35px;
  align-items: center;
  }
  
  .forgotPasswordsaas .ConfirmHeadwebsaas {
    color: #171717;
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 30px 0;
  }
  
  .forgotPasswordsaas .ConfirmSubHeadwebsaas {
    color: #312820;
  font-size: 16px;
  font-weight: 400;
  }
  .forgotPasswordsaas .confirmTab img {
    background-color: #eff6f5;
    border-radius: 50%;
    margin-inline-end: 50px;
  }
  .ConfirmHeadwebsaaspassGen{
    width: 406%;
  }
  .ResetPasswordWebsaas{
    text-align: center;
    margin-inline-start: 43px;
    width: 118%;
  }
  @media (max-width: 700px) {
    .ResetPasswordWebsaas{
      font-size: 8px;
      width: 116%;
      
    }
  }
  .ForgetPasswordWebsaasMobileCreated{
    width: 100%;
  }
  @media (max-width: 700px) {
    .ForgetPasswordWebsaasMobileCreated{
      font-size: 8px;
      width: 70%;
      margin-inline-start: -328px;
      margin-top: 250px;
      
    }
  }
  .cryonWebsaasMobileView{
   

  }
  @media (max-width: 700px) {
    .cryonWebsaasMobileView{
margin-top: -181px;
margin-bottom: 10px;
     margin-inline-start: -370px; 
      display: flex;
      flex-direction: column;
      
    }
  }
  .adjustResetPasswordWebSaas{
    width: 100%;
    margin-inline-start: 193px;
  }
  .closeBtnWebsaas{
    justify-content: center;
    width: 140%;
    margin-inline-start: 102px;
  }
  @media (max-width: 700px) {
  .ForgetPasswordWebsaasMobile{
    font-size: 15px;
    width: 380px;
    margin-inline-start: -489px;
  }
}
.ForgetPasswordWebsaasMobileInput{
  font-size: 15px;
  width: 700px;
  margin-inline-start: 100px;
}
@media (max-width: 700px) {
  .ForgetPasswordWebsaasMobileInput{
    font-size: 15px;
    width: 380px;
    margin-inline-start: -309px;
  }
}
.NeedHelpCallMobileView{
  
  margin-inline-start: 180px;
}
@media (max-width: 700px) {
  .NeedHelpCallMobileView{
    font-size: 8px;
    margin-inline-start: 45px;
  }
}
.errormsgstylecolorwebsaas{
  color: #F51919;
}
.faqQuestion {
  display: flex;
  align-items: baseline;
  padding-bottom: 1.8vw;
  margin-top: 1.8vw;
  border-bottom: 1px solid #707070;
}

.faqQuestion .index {
  color: #000000;
  font-size: 1vw;
  margin-inline-end: 2vw;
  font-weight: 500;
  position: relative;
  bottom: 1px;
}
.faqQuestion .item {
  flex-grow: 1;
}
.faqQuestion .item .itemBody {
  color: rgba(29, 29, 31, 0.8);
  font-size: 1vw;
  margin: 0;
  margin-top: 1.7vw;
  max-width: 68%;
}

.faqQuestion .item .itemHead {
  display: flex;
  justify-content: space-between;
  font-size: 1.3vw;
  font-weight: 500;
  cursor: pointer;
}
.faqQuestion .item .itemHead svg {
  margin-inline-start: 5px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .faqQuestion .index {
    font-size: 13px;
  }
  .faqQuestion .item .itemHead {
    font-size: 13px;
  }
  .faqQuestion .item .itemBody {
    max-width: 90%;
  }
  .faqQuestion .item .itemBody {
    font-size: 11px;
  }
}

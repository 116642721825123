.spfExpandableCard {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 35vw;
  padding-top: 1.77vw;
  padding-inline-start: 3.69vw;
  padding-inline-end: 1.77vw;
  padding-bottom: 10.1vw;
  overflow: hidden;
  margin-bottom: 2.6vw;
  margin-inline-end: 2.1vw;
  position: relative;
}
.spfExpandableCard.first {
  padding-bottom: 15.4vw;
}

.spfExpandableCard.upperOffset {
  margin-top: 14vw;
}

.spfExpandableCard .expBtn {
  width: 2.7vw;
  height: 2.7vw;
  line-height: 2.7vw;
  text-align: center;
  background: rgba(255, 255, 255, 0.59);
  cursor: default;
  align-self: flex-end;
  margin-bottom: 0.4vw;
  border-radius: 50px;
}

.spfExpandableCard .cardIcon {
  margin-bottom: 1.19vw;
}
.spfExpandableCard h1 {
  font-weight: 700;
  font-size: 2.5vw;
  margin-bottom: 1.04vw;
  white-space: pre-line;
}
.spfExpandableCard p {
  color: rgba(65, 65, 65, 0.8);
  font-size: 1.35vw;
  margin-bottom: 2.604vw;
  white-space: pre-line;
}
.spfExpandableCard ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.spfExpandableCard li {
  display: flex;
  color: rgba(6, 6, 6, 0.8);
  font-size: 0.937vw;
  margin-bottom: 0.5vw;
  font-weight: 500;
}
.spfExpandableCard li:last-child {
  margin-bottom: 0;
}
.spfExpandableCard li svg {
  margin-inline-end: 0.78vw;
}
.spfExpandableCard img {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: -9vw;
}

.spfExpandableCard.first img {
  bottom: -6vw;
}

@media (max-width: 768px) {
  .spfExpandableCard .expBtn {
    width: 12px;
    height: 12px;
    line-height: 12px;
  }
  .spfExpandableCard .expBtn svg {
    width: 12px;
  }

  .spfExpandableCard .cardIcon svg {
    width: 22px;
  }
  .spfExpandableCard h1 {
    font-size: 2.5vw;
  }
  .spfExpandableCard p {
    font-size: 2vw;
  }
  .spfExpandableCard p br {
    display: none;
  }
  .spfExpandableCard li {
    font-size: 1.5vw;
    align-items: center;
  }
  .spfExpandableCard li:last-child {
    margin-bottom: 0;
  }
  .spfExpandableCard li svg {
    width: 10px;
  }
}

.signupPage {
  padding-inline-start: 144px;
}
.signupParentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: auto;
}

.signupPage .signupHeader {
  text-align: start;
  margin-top: 100px;
  font-size: 45px;
  font-family: FreightBig Pro;
  font-weight: 900;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 26px;
}

.signupPage .signupParagraph {
  display: inline;
  font-size: 18px;
  font-family: Forma DJR Micro;
  font-weight: 500;
  letter-spacing: 0px;
  color: #241c15;
  opacity: 1;
}

.signupPage .flex-container {
  display: flex;
  flex-direction: row;
  width: 511px;
}

.signupPage .btn {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid #bdbab8;
  border-radius: 2px;
  opacity: 1;
  margin-top: 20px;
  margin-inline-start: 81px;
  width: 512px;
  height: 57px;
}

.signupPage .btnSubmit {
  margin-top: 15px;
  width: 511px;
  height: 59px;
  color: #ffffff;
  background: #0f1d42 0% 0% no-repeat padding-box;
  border-radius: 2px;
  cursor: pointer;
  opacity: 1;
}

.signupPage .btn-block {
  display: block;
  width: 100%;
}

.signupPage .googleButton {
  background-color: #ffffff;
  color: #171717;
  font-size: 16px;
  border: 2px solid #bdbab8;
  border-radius: 2px;
  opacity: 1;
  margin-top: 20px;
  padding-inline-start: 15px;
  width: 512px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.signupPage .linkedInButton {
  background-color: #0077b5;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  border: 2px solid #0077b5;
  border-radius: 2px;
  opacity: 1;
  margin-top: 20px;
  padding-inline-start: 15px;
  width: 512px;
  height: 57px;
  justify-content: flex-start;
  align-items: center;
}

.signupPage .googleButton span,
.signupPage .linkedInButton span {
  margin-inline-start: 150px;
}

.signupPage .logo {
  display: block;
  margin-top: 27px;
  width: 164px;
  height: 55px;
  background: transparent;
  opacity: 1;
}
.signupPage footer {
  text-align: center;
  margin-top: 154px;
  max-width: 462px;
  font-size: 15px;
}
.FirstLastNameSignup {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.signupInputWidthStyle {
  width: 96%;
  margin-bottom: 15px;
}
.signupInputWidthPassStyle {
  width: 96%;
  margin-bottom: 35px;
}
.AlreadyHaveFooter {
  text-align: center;
  margin-top: 23px;
}
.AlreadyHaveFooter a,
.AlreadyHaveFooter a:hover {
  text-decoration: none;
  font-size: 18px;
  font-family: Forma DJR Micro;
  font-weight: 700;
  letter-spacing: 0px;
  color: #1d1c1c;
}
.privacyPolicySig a,
.privacyPolicySig a:hover {
  text-decoration: underline;
  font-size: 14px;
  font-family: Forma DJR Micro;
  font-weight: normal;
  letter-spacing: 0px;
  color: #357ce5;
}
.AlreadyHaveFooter span {
  font-size: 18px;
  font-family: Forma DJR Micro;
  font-weight: 500;
  letter-spacing: 0px;
  color: #787573;
}
.containersignupimgright {
  position: relative;
  text-align: left;
  color: #242424;
}
/* Bottom left text */
.bottom-left-signup {
  position: fixed;
  width: 500px;
  float: right;
  margin-top: 645px;
  margin-inline-start: 75px;
}
.signupSideImageIdentity {
  position: fixed;
  width: 45%;
  height: 100%;
  z-index: -1;
}
.writingOnSignupImage {
  z-index: 1;
  position: fixed;
  bottom: 2%;
  padding-inline-start: 47px;
}
.writingOnSignupImage h3 {
  font-size: 37px;
  font-family: FreightBig Pro;
  font-weight: 900;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.writingOnSignupImage p {
  font-size: 18px;
  font-family: FreightBig Pro;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.8;
}
.writingOnSignupImageParag {
  font-size: 18px;
  font-family: FreightBig Pro;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.spfInnovativeBackGround {
  position: relative;
  background: black;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 0;
}
.spfInnovative {
  position: relative;
  background: black;
  width: 80%;
  height: 75%;
  margin-inline-start: 9.5vw;
  z-index: 1;
}
.spfInnovative .innovativeOverlay {
  position: relative;
  width: 100%;
  height: 75%;
  opacity: 0.2;
  z-index: 10;
}
.spfInnovative img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.arrowdownSPF,
.arrowdownSPF:hover {
  z-index: 10;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
.spfInnovative span {
  font-size: 1vw;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  z-index: 10;
}
.spfInnovative.arrowdownSPF {
  z-index: 10;
  cursor: pointer;
  font-size: 1vw;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
.spfInnovativeBackGround.spfInnovative a {
  font-size: 1vw;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
.spfInnovative li {
  font-size: 1vw;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
.spfInnovative svg {
  font-size: 7px;
  text-align: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 10;
  margin-inline-end: 12px;
}
.SPFParagraph {
  font-size: 1.71vw;
  font-weight: 400;
  color: #ffffff;
  margin-inline-start: 10.5vw;
  padding-bottom: 10.94vw;
  margin-bottom: 0;
  z-index: 10;
}
.SPFSubTitleParagraph {
  padding-top: 11.2vw;
  font-weight: 500;
  font-size: 3.3vw;
  margin-bottom: 1.77vw;
  margin-inline-start: 10.5vw;
  color: white;
  z-index: 10;
}
.innovationMoreDetails {
  margin-inline-start: 12px;
  margin-top: 2.5px;
}
.innovationMoreDetailsFlex {
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .spfInnovative p {
    font-size: 15px;
    text-align: center;
    margin-inline-start: 1.5vw;
  }

  .spfInnovative svg {
    font-size: 7px;
    text-align: center;
    width: 10px;
    height: 10px;
    margin-inline-end: 10px;
  }
  .spfInnovative h1 {
    padding-top: 11.2vw;
    font-weight: 700;
    font-size: 6.3vw;
    margin-bottom: 1.77vw;
    margin-inline-start: 1.5vw;
    text-align: center;
    color: white;
  }
  .spfInnovative {
    position: relative;
    background: black;
    width: 97%;
    height: 75%;
    margin-inline-start: 7px;
  }
  .arrowdownSPF,
  .arrowdownSPF:hover {
    z-index: 10;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  }
  .innovationMoreDetailsFlex {
    display: flex;
    flex-direction: row;
    text-align: center;
  }
  .innovationMoreDetails {
    margin-inline-start: 20px;
  }
}

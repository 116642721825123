.resetPassword {
  max-width: 859px;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resetPassword .logo {
  margin-bottom: 50px;
}

.resetPassword .resetHead {
  font-size: 45px;
  color: #000000;
  font-weight: 500;
  text-align: center;
  margin: 0;
  font-family: Raleway;
}

.resetPassword .resetSubHead {
  width: 67%;
  text-align: center;
  font-size: 16px;
  margin: 28px 0 50px 0;
}

.resetPassword .resetContent {
  width: 85%;
}
.resetPassword .helper {
  text-align: center;
  color: #787573;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
}
.resetPassword .helper a {
  color: #1d1c1c;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
}

.contactParentContainer {
  border: 1px solid #e6e4e3;
  border-radius: 6px;
  opacity: 1;
  width: 30.31vw;
  padding: 3.125vw;
}

.contactParentContainer input {
  width: 100%;
}
/* .inputFirstnameNotifyMe {
  width: 226px;
  height: 60px;
  margin-inline-end: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bdbab8;
  border-radius: 2px;
  margin-left: 24px;
  opacity: 1;
}
.inputLastnameNotifyMe {
  width: 226px;
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bdbab8;
  border-radius: 2px;
  margin-right: 14px;
  opacity: 1;
}
.inputEmailNotifyMe {
  width: 461px;
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bdbab8;
  border-radius: 2px;
  opacity: 1;
} */
.flex-container-NotifyMe {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.contactParentContainer .inputControl {
  flex-grow: 1;
}
.flex-container-NotifyMe .inputControl {
  width: 50%;
}
.flex-container-NotifyMe input {
  width: 100%;
}
/* .inputLastnamePhoneNumer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bdbab8;
  border-radius: 2px;
  opacity: 1;
} */

.contactParentContainer .countryCont {
  display: flex;
  align-items: center;
  gap: 10px;
}

.countryCont div:first-child {
  position: relative;
  top: 3.5px;
}

.contactParentContainer .contactmeBtn {
  background-color: rgba(47, 83, 235);
  color: white;
  width: 100%;
  border-radius: 3px;
  padding-top: 0.781vw;
  padding-bottom: 0.781vw;
  border: none;
  font-weight: 500;
  font-size: 16px;
}
.contactParentContainer .contactmeBtn:disabled,
.contactParentContainer .contactmeBtn.loading {
  /*opacity: 0.22;*/
  background-color: #CBC3E3;
  pointer-events: none;
}

@media (max-width: 768px) {
  .contactParentContainer {
    width: 100%;
  }

  .contactParentContainer .contactmeBtn {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.GetAMeetingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 13.59vw;
  padding-left: 14.5vw;
  padding-right: 14.5vw;
}

.GetAMeetingDescription {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 5.74vw;
}

.NeedHelp {
  font-size: 0.93vw;
  color: black;
  margin-bottom: 0.625vw;
  align-self: flex-start;
}
.SitDown {
  color: black;
  font-family: FreightBig Pro;
  font-weight: 700;
  font-size: 3.43vw;
  margin-bottom: 1.822vw;
  white-space: pre-line;
}
.OurExperts {
  color: rgba(65, 65, 65, 1);
  white-space: pre-line;
  font-size: 1.35vw;
}
.GetMeetingPanner {
  margin-bottom: 4.11vw;
}
.GetMeetingPanner img {
  width: 100%;
  border-radius: 6px;
}
.EnterYourContact {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.NotifyMeText {
  padding-top: 5vw;
}

.EnterContactHeaderText {
  font-size: 2.5vw;
  font-weight: 700;
  white-space: pre-line;
  margin-bottom: 1.56vw;
}
.EnterContactDetailsText {
  font-size: 1.35vw;
  color: rgba(65, 65, 65, 0.8);
  white-space: pre-line;
}
.NotifyMeAndContact {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-inline-start: 3vw;
  padding-inline-end: 3vw;
}

@media (max-width: 768px) {
  .GetAMeetingContainer {
    padding-left: 4.5vw;
    padding-right: 4.5vw;
  }
  .NeedHelp {
    font-size: 9px;
  }
  .SitDown {
    font-size: 24px;
  }
  .OurExperts {
    font-size: 11px;
  }
  .NotifyMeAndContact {
    flex-direction: column;
  }
  .EnterContactHeaderText {
    font-size: 14px;
    white-space: normal;
  }
  .EnterContactDetailsText {
    font-size: 12px;
    margin-bottom: 20px;
    width: 80%;
    white-space: normal;
  }
}

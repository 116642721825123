.chooseBannerContainer .CheckIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 34px;
  height: 34px;
  background: #0f1d42 0% 0% no-repeat padding-box;
  /* border: 4px solid #0f1d42; */
  border-radius: 50%;
}
.chooseBannerContainer .ImageCardContainer {
  position: relative;
}
.chooseBannerContainer .VerificationStepsUploadLogoChooseBanner {
  width: 43vw;
}
.chooseBannerContainer .choosebannerbutton span {
  color: #ffffff;
}
.chooseBannerContainer .choosebannerbutton {
  width: 43vw;
}
.chooseBannerContainer .ImageGallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 5px;
}
.chooseBannerContainer .ImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chooseBannerContainer .ImgContainer img {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 3px;
  width: 303px;
  height: 402px;
  /* border: 5px solid #0f1d42; */
  border-radius: 3px;
}

body {
  padding: 0;
  margin: 0;
}

.chooseBannerContainer .containerScroller {
  width: 100%;
  height: 16vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.chooseBannerContainer .containerScroller > h1 {
  color: rgb(255, 234, 195);
}

.chooseBannerContainer .carousel {
  width: 700px;
  height: 300px;
  background: rgba(201, 165, 118, 0.37);
  direction: rtl;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.chooseBannerContainer .carousel.active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.chooseBannerContainer .cWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chooseBannerContainer .item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chooseBannerContainer .item:first-child {
  margin-right: 0px !important;
}
.chooseBannerContainer .item:last-child {
  margin-left: 0px !important;
}

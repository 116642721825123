.pannerContainer {
  position: relative;
}
.pannerContainer img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.pannerContainer .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}

.panner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 10;
  padding-top: 13.5vw;
  padding-bottom: 4.84vw;
}
.aboutus {
  color: #fff;
  font-size: 0.93vw;
  z-index: 10;
  white-space: pre-line;
  width: 45vw;
  text-align: center;
}
.reshaping {
  color: #fff;
  font-size: 3.4375vw;
  text-align: center;
  font-family: FreightBig Pro;
  margin-bottom: 10.93vw;
  z-index: 10;
  width: 45vw;
}
.pannerContainer .arrowdown {
  z-index: 10;
}

@media (max-width: 768px) {
  .aboutus {
    font-size: 10px;
  }
  .reshaping {
    font-size: 16px;
  }
  .pannerContainer .arrowdown svg {
    width: 20px;
  }
}

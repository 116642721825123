.ColoringFontCrName{
    color: #FFFFFF;
}
.adjustAddCompanyWidthDetails{
    width: 110%;
  
}
.adjustmarginfortheFormAddCompany{
    margin-inline-start: 180px;
    width: 65%;
}
.enteryourDetailsFont h2{
font: normal normal bold 2.239vw Forma DJR Micro;
letter-spacing: 0px;
color: #171717;
opacity: 1;
}
.StyleToAddyourCompanyFont p {
font: normal normal normal 18px/28px Forma DJR Micro;
letter-spacing: 0px;
color: #312820;
opacity: 1;
}
.btnSubmitAddCompany .disabled {
    margin-top: 15px;
    width: 100%;
    height: 3.07vw;
    color: #FFFFFF;
    background: #787573 0% 0% no-repeat padding-box;
    border:#787573;
    border-radius: 2px;
    opacity: 1;
  } 

  .btnSubmitAddCompany {
    margin-top: 15px;
    width: 100%;
    height: 4.0729vw;
    color: #FFFFFF;
    background: #0F1D42 0% 0% no-repeat padding-box;
    border:#0F1D42 ;
    border-radius: 2px;
    opacity: 1;
  }
  .errormsgcolor{
      color: #F51919;
  }
.homeMaxSec {
  background: white;
  padding-top: 12vw;
  padding-bottom: 17.1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeMaxSec svg {
  margin-bottom: 1.875vw;
}

.homeMaxSec h1 {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 2.3vw;
  white-space: pre-line;
}

.homeMaxSec p {
  font-size: 1.3vw;
  color: rgba(65, 65, 65, 0.8);
  margin: 0;
  white-space: pre-line;
  text-align: center;
}

@media (max-width: 768px) {
  .homeMaxSec svg {
    width: 40;
    height: 40;
  }

  .homeMaxSec h1 {
    font-size: 28px;
  }

  .homeMaxSec p {
    font-size: 12px;
  }
}

.homereadytosignup {
  background: black;
  padding: 11.5vw 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.homereadytosignup .images {
  display: flex;
  align-items: center;
  margin-inline-end: 3.5vw;
}

.homereadytosignup .images .s1 {
  margin-inline-end: 10px;
}

.homereadytosignup .images .s2 {
  display: flex;
  flex-direction: column;
}
.homereadytosignup .images .s2 img:first-child {
  margin-bottom: 14px;
}

.homereadytosignup .sectionText h1 {
  font-size: 4vw;
  color: white;
  font-weight: 700;
}
.homereadytosignup .sectionText a {
  text-decoration: none;
}
.homereadytosignup .sectionText button {
  display: flex;
  flex-direction: column;
  background: transparent;
  color: white;
  border: none;
  margin-top: 3vw;
}
.homereadytosignup .sectionText button svg {
  margin-top: 2vw;
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .homereadytosignup .images {
    display: none;
  }
  .homereadytosignup .sectionText h1 {
    font-size: 30px;
  }
  .homereadytosignup .sectionText button {
    font-size: 16px;
  }
}

.emailVerification .mainContainer {
  max-width: 609px;
  margin: auto;
}

.emailVerification .mainContainer .codeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.emailVerification .mainContainer .codeInputLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 59px;
  margin-bottom: 9px;
}

.emailVerification .mainContainer .emailLabel {
  font-size: 16px;
}

.emailVerification .mainContainer .codeHeader {
  font-size: 45px;
  text-align: start;
  margin-top: 20px;
  width: 680px;
}

.emailVerification .mainContainer .codeParagraph {
  font-size: 16px;
  text-align: start;
  margin-top: 28px;
}
.emailVerification .mainContainer .codeParagraph a {
  color: #007094;
  cursor: pointer;
}
.emailVerification .mainContainer .bottomParagraph {
  margin-top: 18px;
  text-align: center;
  font: normal normal normal 15px/23px Poppins;
  letter-spacing: 0px;
  color: #787573;
}

.emailVerification .mainContainer .errorParagraph {
  color: #e92121;
  text-align: start;
}

.emailVerification .mainContainer input.codeInput:focus {
  background-color: #ffffff;
}

.emailVerification .mainContainer input.codeInputError:focus {
  outline: none;
  border: 1px solid #e92121;
}

.emailVerification .mainContainer .codeInput {
  text-align: center;
  width: 89px;
  font-size: 24px;
  height: 55px;
}

.emailVerification .mainContainer .codeInputError {
  border: 1px solid #e92121;
  text-align: center;
  width: 89px;
  font-size: 24px;
  height: 55px;
}

.emailVerification .mainContainer .flex a:hover {
  cursor: pointer;
}
.emailVerification .mainContainer .mainButton {
  margin-top: 17px;
}
.emailVerification .mainContainer .mainButton:disabled {
  width: 609px;
  height: 59px;
  background: #787573 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 0.1;
}

.emailVerification .mainContainer .verificationFooter {
  margin-inline-start: 0px;
  margin-bottom: 60px;
  width: 462px;
  height: 79px;
  text-align: start;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #787573;
  opacity: 1;
}

.emailVerification .mainContainer .verificationLowerTag {
  font-weight: 500;
  font-size: 15px;
  font: normal normal medium 15px/23px Poppins;
  color: #1d1c1c;
  letter-spacing: 0px;
}

.emailVerification .mainContainer a.verificationLowerTag:hover {
  cursor: pointer;
  text-decoration: none;
  color: #1d1c1c;
}

.emailVerification .mainContainer .verificationUpperTag {
  margin-top: 100px;
  text-align: start;
  font-size: 15px;
  font: normal normal medium 15px/23px Poppins;
  letter-spacing: 0px;
  color: #007094;
  opacity: 0.8;
  display: inline-block;
  cursor: pointer;
}

.emailVerification .mainContainer .card {
  height: 69px;
  background: #eff6f5 0% 0% no-repeat padding-box;
  color: #171717;
  border: 1px solid #9babc5;
  border-radius: 3px;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 15px;
}
.emailVerification .mainContainer .flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.emailVerification .mainContainer form span.card.flex span:first-child {
  margin-inline-start: 10px;
}

.emailVerification .mainContainer .cardBtn {
  width: 144px;
  height: 42px;
  background: #f0f2f5 0% 0% no-repeat padding-box;
  color: #4a5973;
  border: #4a5973;
  border-radius: 2px;
  opacity: 1;
}

.faqcontainer {
  background: #f6f6f6;
  padding: 9vw 0;
  border-radius: 8px;
}

.faqcontainer .contHeader {
  font-weight: 500;
  font-size: 2.3vw;
  margin-bottom: 4.5vw;
  text-align: center;
}

.faqcontainer .questionsCont {
  max-width: 56%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .faqcontainer .contHeader {
    font-size: 18px;
  }
  .faqcontainer .questionsCont {
    max-width: 85%;
  }
}

.spfcustomize {
  position: relative;
}
.spfcustomize .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
  z-index: -1;
}
.spfcustomize img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.spfcustomize h1 {
  padding-top: 29.2vw;
  font-weight: 700;
  font-size: 5.3vw;
  margin-bottom: 1.77vw;
  text-align: center;
  color: white;
}

.spfcustomize p {
  font-size: 1.35vw;
  color: rgba(255, 255, 255, 0.8);
  margin-inline-start: 26vw;
  padding-bottom: 14.94vw;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .spfcustomize p {
    font-size: 10px;
  }
}

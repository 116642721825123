.fintoldNav .navbar {
  background: transparent;
  padding: 0;
}
.fintoldNav .fixedNav {
  background: white;
}

.fintoldNav .navbarContent {
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
}

.fintoldNav .navbar-brand img {
  width: 130px;
}

.fintoldNav .navbarContent.withBorder {
  border-bottom: 1px solid #707070;
}

.fintoldNav .nav-item {
  margin-inline-start: 27px;
  margin-inline-end: 27px;
  margin-bottom: -1px;
}
.fintoldNav .nav-item.withBorder {
  border-bottom: 1px solid #43ec23;
}
.fintoldNav .nav-item:first-child {
  margin-inline-start: 0px;
}

.fintoldNav .nav-item:last-child {
  margin-inline-end: 0px;
}

.fintoldNav .nav-link {
  opacity: 1 !important;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #ffffff !important;
}

.fintoldNav button.nav-link {
  background-color: transparent;
  border: none;
  position: relative;
}

.fintoldNav .nav-link span {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff !important;
}

.fintoldNav .fixedNav .nav-link {
  color: black !important;
}
.fintoldNav .nav-item .nav-link.withBorder,
.fintoldNav .nav-item .nav-link.withBorder span {
  color: #43ec23 !important;
}

.fintoldNav ul.dropdown-menu.solutionsDropDown.show {
  background-color: #ffffff;
  width: 100%;
  height: 28.0555556vh;
  top:95%;
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li{

  width: 50%;
  height: 100%;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li:hover{
  background-color: #F1F1F1;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li a{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li a div{
  width: 24.4583333vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li a span:first-child{
  font-size: 0.83333vw;
  font-weight: 500;
  color: #000;
  margin-bottom: 1.1111111vh;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li a span:nth-child(2){
  font-size: 2.34375vw;
  font-weight: 500;
  font-family: FreightBig Pro;
  line-height: 4.259259vh;
  color: #000;
  word-wrap: break-word;
  margin-bottom: 2.1296296vh;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li a span:last-child{
  font-size: 0.989583333vw;
  color: #2323EC;
}
.fintoldNav ul.dropdown-menu.solutionsDropDown li a span:last-child svg{
  margin-inline-start: 0.885416667vw;
}
.fintoldNav button.nav-link span.caret{
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #FFFFFF;
  bottom: 0;
  left: 36%;
  z-index: 2000;
}

.fintoldNav .controlCont {
  display: flex;
  align-items: center;
}

.fintoldNav .controlCont > * {
  margin-inline-end: 40px;
}
.fintoldNav .controlCont > :last-child {
  margin-inline-end: 0;
}
.fintoldNav .whitesecBtn {
  font-size: 14px;
}

.fintoldNav ul.dropdown-menu {
  padding: 0;
}
.fintoldNav ul.dropdown-menu a {
  padding: 10px;
}

.fintoldNav .mobileBrand {
  display: none;
}

@media (max-width: 768px) {
  .fintoldNav .navbar-brand img {
    width: 90px;
  }
  .fintoldNav .navbar {
    background: white;
    position: relative;
    padding: 10px;
  }
  .fintoldNav .nav-link {
    color: black !important;
  }
  .fintoldNav .nav-item {
    margin-inline-start: 15px;
  }
  .fintoldNav .nav-item:first-child {
    margin-inline-start: 15px;
  }

  .fintoldNav .nav-item:last-child {
    margin-inline-end: 15px;
  }
  .fintoldNav .whitesecBtn,
  .fintoldNav .whitesecBtn.inverted {
    background: #000000;
    border: 1px solid black;
    color: white;
  }
  .fintoldNav .mobileBrand {
    display: unset;
  }
  .fintoldNav .pcbrand {
    display: none;
  }
  .fintoldNav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
  .fintoldNav .navbarContent.withBorder {
    border-bottom: none;
  }
  .fintoldNav .nav-item.withBorder {
    border-bottom: none;
  }
  .fintoldNav .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

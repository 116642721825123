.Container {
  display: grid;
  grid-template-columns: auto 43.22vw auto;
  grid-template-rows: auto auto auto auto auto;
  margin-top: 165px;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
}
.Content {
  display: grid;
  row-gap: 30px;
  width: 100%;
}
.UploadCompanyLogo {
  text-align: left;
  font: normal normal bold 35px/41px Raleway;
  color: rgba(23, 23, 23, 1);
}
.BrandYourDashboard {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: rgba(49, 40, 32, 1);
}
.UploadYourLogo {
  text-align: left;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: rgba(49, 40, 32, 1);
}
.UseLogoButton {
  background: rgba(15, 29, 66, 1) 0% 0% no-repeat padding-box;
  border-radius: 2px;
  height: 59px;
  width: 100%;
}
.UseLogoButton span {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}
.VerificationStepsUploadLogo {
  width: 100%;
  height: 25px;
}
.CustomerLogin {
  font: normal normal medium 25px/38px Poppins;
  color: rgba(35, 32, 30, 1);
}
.ThisBannerWillBeUsed {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: rgba(49, 40, 32, 1);
}
.BannerButton {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 11px rgba(240, 242, 245, 1);
  border: 1px solid rgba(228, 228, 229, 1);
  border-radius: 3px;
  margin-top: 10px;
  height: 4.9vw;
  width: 100%;
}
/* slider */
.slidercontainer {
  position: absolute;
  left: 10%;
  top: 85%;
  width: 1000px;
  height: 200px;
  overflow: hidden;
}
.slidercards {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(6, 300px);
  grid-gap: 50px;
  pointer-events: none;
}
.slider-image {
  height: 100%;
  background-color: blue;
  text-align: center;
  padding: 3rem;
}

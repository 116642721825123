.SignUpContNbfi {
  max-width: 900px;
  padding: 0 50px;
  margin: 0 auto;
}

.signupCompanyLogoNbfi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
}

.signupCompanyLogoNbfi img {
  max-width: 364px;
  max-height: 84px;
  margin-left: 79px;
}
.signupCompanyLogoNbfi .companyHelpNumNbfi {
  color: #707070;
  font-size: 14px;
  border-inline-start: 1px solid #787573;
  padding-inline-start: 25px;
  margin-inline-start: 10px;
}
.signupFormCont .headNbfi {
  font-size: 45px;
  font-family: FreightBig Pro;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 26px;
}
@media (max-width: 700px) {
  .signupCompanyLogoNbfi .companyHelpNumNbfi {
    font-size: 8px;
    margin-inline-start: 10px;
    margin-top: 30px;
    width: 100%;
  }
}
.styleWebsaasForgotPassword {
}
@media (max-width: 700px) {
  .styleWebsaasForgotPassword {
    width: 50%;
  }
}
.signupCompanyLogoNbfi .companyHelpNumNbfi a {
  color: #0f1d42;
  font-weight: 600;
  font-size: inherit;
  text-decoration: none;
}

.signupFormContNbfi {
  max-width: 628px;
  margin: 50px auto;
}
.signupFormContNbfi h1 {
  margin-bottom: 26px;
}

.signupFormCont .headNbfi {
  color: #000000;
  font-size: 45px;
  font-weight: 900;
  margin: 0 0 28px 0;
  font-family: FreightBig Pro;
}
.signupFormCont .subheadNbfi {
  color: #241c15;
  font-size: 16px;
  width: 85%;
  margin: 0 0 35px 0;
}

.signupFormCont .googleButton {
  border: 2px solid #bdbab8;
  background: white;
  color: #171717;
  text-align: center;
  border-radius: 2px;
  position: relative;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  margin: 0 0 15px 0;
}

.signupFormCont .linkedInButton {
  border: none;
  background: #0077b5;
  color: white;
  border-radius: 2px;
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
}

.btnLogo {
  position: absolute;
  left: 5%;
  top: 35%;
  width: 18px;
  height: 18px;
}

.orSep {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 28px 0;
}
.orSep .text {
  font-weight: 300;
  color: #656565;
  font-size: 16px;
  padding: 0 13px;
  text-transform: capitalize;
}
.orSep .line {
  flex-grow: 1;
  height: 1px;
  background-color: #dddddd;
}

.haveAccount {
  text-align: center;
  margin: 20px 0;
  font-size: 16px;
  color: #787573;
}

.haveAccount a {
  color: #1d1c1c;
  font-weight: 500;
  cursor: pointer;
}

.signupFooter {
  color: #787573;
  font-size: 14px;
}

.signupFooter.center {
  width: 75%;
  margin: 100px auto 0 auto;
}

.signupFooter a {
  color: #357ce5;
  text-decoration: underline;
  cursor: pointer;
}

.signupFooter a:hover {
  color: #357ce5;
  text-decoration: underline;
  cursor: pointer;
  opacity: 80%;
}

.signupSideImageNbfi {
  position: fixed;
  width: 33.3333%;
  height: 100%;
}

.SignUpContNbfi .helperNbfi {
  text-align: center;
  color: #787573;
  font-weight: 500;
  font-size: 16px;
  margin-top: 15px;
}

.SignUpContNbfi .helperNbfi a,
.SignUpContNbfi .helperNbfi a:hover {
  color: #1d1c1c;
  font-weight: 500;
  font-size: 16px;
  margin-top: 15px;
  text-decoration: none;
  text-align: center;
}
.btnSubmitLogin {
  margin-top: 15px;
  margin-inline-start: -2px;
  width: 100%;
  height: 59px;
  color: #ffffff;
  background: #0f1d42 0% 0% no-repeat padding-box;
  border: #0f1d42;
  border-radius: 2px;
  opacity: 1;
}
.ColoringDotToGreenLogin {
  color: #43ec23;
}
.stylingFinToldLogin {
  font: normal normal 800 35px/44px Geometria;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 91.5px;
  width: 100%;

}
.marginleftNeedHelpLogin {
  margin-inline-start: 177px;
  margin-top: -39px;
}
.writingOnLoginImage {
  z-index: 1;
  position: fixed;
  bottom: 2%;
  padding-inline-start: 47px;
}
.writingOnLoginImage h3 {
  font-size: 37px;
  font-family: FreightBig Pro;
  font-weight: 900;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.writingOnLoginImage p {
  font-size: 18px;
  font-family: FreightBig Pro;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.8;
}
.writingOnLoginImageParag {
  font-size: 18px;
  font-family: FreightBig Pro;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.FooterLoginNewStyle {
  margin-top: 162px;
}

.privacyPolicylogg a,
.privacyPolicylogg a:hover {
  text-decoration: underline;
  font-size: 14px;
  font-family: Forma DJR Micro;
  font-weight: normal;
  letter-spacing: 0px;
  color: #357ce5;
}

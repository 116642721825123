.ShareWithFriendsContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  border: 1px solid #e6e4e3;
  padding: 5.2vw 12.2vw;
}
.ShareWithFriendsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.SocialMediaIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.MaximizeIcon {
  align-self: flex-end;
}
.ShareWithFriendsTitle {
  text-align: left;
  font-family: "FreightBig Pro";
  font-size: 3.125vw;
  font-weight: 700;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 3.5vw;
}
.ShareWithFriendsPostUrl {
  text-align: left;
  font-weight: 500;
  letter-spacing: 0px;
  color: #23201e;
  opacity: 1;
  width: 100%;
}
.ShareWithFriendsPostUrl input {
  width: 100%;
}
.ShareWithFriendsPostLink {
  text-align: left;
  font: normal normal medium 16px/19px Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.ShareWithFriendsShareOn {
  text-align: left;
  font: normal normal medium 16px/19px Forma DJR Micro;
  letter-spacing: 0px;
  color: #23201e;
  opacity: 1;
}

@media (max-width: 768px) {
  .ShareWithFriendsTitle {
    font-size: 24px;
  }
}

.TermsContainer {
  padding-top: 7.44vw;
  position: relative;
  overflow: hidden;
}

.TermOfServiceTitle {
  text-align: left;
  color: #171616;
  opacity: 1;
  margin-bottom: 3.43vw;
  font-size: 3.4375vw;
  font-weight: bold;
  font-family: "FreightBig Pro";
  white-space: pre-line;
}
.TermNumber {
  opacity: 1;
  font-size: 1.66vw;
  font-weight: 500;
}
/* .TermTitle {
  text-align: left;
  font: normal normal medium 32px/88px Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.TermContent {
  text-align: left;
  font: normal normal normal 18px/24px Forma DJR Micro;
  letter-spacing: 0.18px;
  color: #414141;
  opacity: 1;
  width: 748px;
  padding-top: 35px;
  padding-left: 64px;
  padding-bottom: 35px;
}
.TermtitleContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20 px;
} */
.TermsContainerImagesAndText {
  margin-inline-start: 28.5vw;
  width: 42.8vw;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.img1 {
  width: 78.3vw;
  position: absolute;
  top: -16.5vw;
  inset-inline-start: -48vw;
}
.img2 {
  width: 78.3vw;
  position: absolute;
  top: -20.5vw;
  inset-inline-end: -59vw;
}

@media (max-width: 768px) {
  .TermsContainerImagesAndText {
    width: 80%;
    margin-inline-start: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .img1 {
    display: none;
  }
  .img2 {
    display: none;
  }
  .TermOfServiceTitle {
    font-size: 28px;
  }
}

.avatarContainer {
  width: 24.11vw;
  height: 30.6vw;
  border-radius: 8px;
  opacity: 1;
  position: relative;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
}
.avatarContainer img.founderImg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.founderPosition {
  text-align: start;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 0.6;
  font-size: 12px;
}
.founderName {
  text-align: left;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 0.9;
  font-size: 1.25vw;
}
.avatarFounderContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
}
.bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 1.97vw;
  padding-left: 1.77vw;
  padding-right: 1.77vw;
}
/* .Avatar {
  max-width: 463px;
  max-height: 589px;
} */

.fundingOption {
  display: flex;
  align-items: stretch;
}
.fundingOption .img {
  width: 45%;
  flex-shrink: 0;
}
.fundingOption .img img {
  width: 100%;
  height: 100%;
}

.fundingOption .cont {
  width: 55%;
  flex-shrink: 0;
  padding-top: 24vw;
  padding-inline-start: 14vw;
}
.fundingOption .cont small {
  font-size: 1.2vw;
  margin-bottom: 1vw;
}
.fundingOption .cont h1 {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 4.1vw;
}

.fundingOption .cont p {
  font-size: 1.1vw;
  font-weight: lighter;
}

@media (max-width: 768px) {
  .fundingOption .img {
    display: none;
  }
  .fundingOption .cont {
    width: 100%;
    padding-top: 17.9vw;
    padding-bottom: 17.8vw;
  }
  .fundingOption .cont small {
    font-size: 9px;
  }
  .fundingOption .cont h1 {
    font-size: 28px;
  }
  .fundingOption .cont p {
    font-size: 12px;
  }
}

.everyone {
  background: white;
  display: flex;
  padding-top: 8vw;
  padding-bottom: 8vw;
  justify-content: center;
  align-items: center;
}

.everyone .images {
  display: flex;
}
.everyone .col1 {
  margin-inline-end: 1.56vw;
}
.everyone .col2 {
  margin-top: 3.8vw;
}
.everyone .images img {
  margin-bottom: 1.66vw;
  display: block;
  width: 11.875vw;
}

.everyone .regText {
  display: flex;
  flex-direction: column;
  margin-inline-start: 2.9vw;
}
.everyone .regText small {
  margin-bottom: 1.56vw;
  color: rgba(29, 29, 31, 0.8);
  font-size: 1.09vw;
  font-weight: 500;
}

.everyone .regText h1 {
  color: black;
  font-size: 4vw;
  font-weight: 700;
  white-space: pre-line;
  margin-bottom: 1.56vw;
}

.everyone .regText p {
  color: rgba(29, 29, 31, 0.8);
  font-size: 0.83vw;
  font-weight: 500;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .everyone .regText small {
    margin-bottom: 8px;
  }
  .everyone .regText p {
    font-size: 8px;
  }
}

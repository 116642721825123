.uploadResumeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 164px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px dashed #e4e4e5;
  border-radius: 3px;
  opacity: 1;
}
.parentContainer-ApplyForJob .mainButton {
  width: 50%;
}
.UploadResumeLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin: 5px 30px;
}
.UploadResumeRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin: 5px 30px;
}
.ChooseFile {
  background: #f4f6fe 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  height: 42px;
  border: none;
}
.ChooseFileTxt {
  text-align: center;
  font: normal normal medium 16px/22px ITC Avant Garde Gothic Pro;
  letter-spacing: 0px;
  color: #2884ff;
  opacity: 1;
}
.flex-container-ApplyForJob {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}
.flex-container-ApplyForJob > .inputControl {
  flex-grow: 1;
}
.flex-container-ApplyForJob .inputControl input {
  width: 100%;
  align-items: center;
}
.parentContainer-ApplyForJob {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 51vw;
}
.inputnameApplyForJob {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bdbab8;
  border-radius: 2px;

  opacity: 1;
}
.btnSubmitApplyForJob {
  margin-top: 15px;
  margin-bottom: 30px;
  height: 54px;
  color: #ffffff;
  background: #2f53eb;
  border-radius: 3px;
  opacity: 1;
  border: none;
  width: 50%;
}

@media (max-width: 768px) {
  .flex-container-ApplyForJob {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: stretch;
    gap: 20px;
  }
  .parentContainer-ApplyForJob {
    width: 90vw;
  }
  .parentContainer-ApplyForJob .mainButton {
    width: 100%;
  }
  ul {
    padding-inline-start: 13px;
  }
}

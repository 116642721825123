.CareersContainer {
  padding: 6vw 5vw 2vw 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CareersContainer .CareersTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 20vh;
}
.CareersContainer .CareersTitle .BuildTheFuture {
  text-align: center;
  font-size: 1.5vw;
  color: #000000;
  opacity: 1;
}
.CareersContainer .CareersTitle .BePart {
  text-align: center;
  font-family: FreightBig Pro;
  font-size: 5.4vw;
  font-weight: bold;
  color: #000000;
  opacity: 1;
}
.CareersContainer .horizintalWrap {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 19vh;
}
.BenefitContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.WorkBenefits {
  display: flex;
  text-align: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 28%;
}
.BenefitTitle {
  margin-bottom: 10px;
  font-size: 1.6vw;
  font-family: Forma DJR Micro;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.BenefitDescription {
  text-align: left;
  font: normal normal medium 16px/24px Forma DJR Micro;
  letter-spacing: 0.16px;
  color: #77777b;
  opacity: 0.8;
}

.openPositionsTitle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  margin-top: 15vh;
  max-width: 100%;
  padding: 0 16vw;
}
.OpenPositions {
  text-align: left;
  font: normal normal medium 21px/33px Forma DJR Micro;
  letter-spacing: 0px;
  color: #1d1d1f;
  opacity: 0.8;
}
.FindTheJob {
  text-align: left;
  font-size: 8.5vw;
  font-family: Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.openPositionsTitleStyle {
  text-align: left;
  font-size: 1.6vw;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1d1d1f;
  opacity: 0.8;
}
.FindTheJobStyle {
  text-align: left;
  font-size: 4.5vw;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  white-space: pre-line;
}
.openPositionComponent {
  padding-top: 35px;
  cursor: pointer;
}
.DoneComponent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e4e3;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 45vw;
  margin-top: 30px;
  padding: 10vw 7vw;
}
.DoneStyle {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 25%;
  height: 50%;
}
.DoneText {
  text-align: left;
  font: normal normal bold 78px/88px Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.WeWillNotifyYouNewPostsText {
  text-align: left;
  font: normal normal normal 26px/33px Forma DJR Micro;
  letter-spacing: 0.26px;
  color: #000000;
  opacity: 0.8;
}
.CareersContainer .openPositionComponent {
  width: 100%;
}
@media (max-width: 768px) {
  .BenefitTitle {
    font-size: 15px;
  }
  .FindTheJobStyle {
    font-size: 40px;
    white-space: normal;
  }
  .CareersContainer .horizintalWrap {
    flex-direction: column;
    margin-top: 50px;
  }
  .WorkBenefits {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .CareersContainer .CareersTitle {
    margin-top: 50px;
  }
  .CareersContainer .CareersTitle .BuildTheFuture {
    font-size: 14px;
  }
  .CareersContainer .CareersTitle .BePart {
    font-size: 27px;
  }
  .openPositionsTitle {
    margin-top: 50px;
    padding: 0 5vw;
  }
  .openPositionsTitleStyle {
    font-size: 13px;
  }
  .DoneComponent {
    width: 90vw;
  }
}

.spfPrivacyMatters {
  background: black;
  padding-top: 35.1vw;
  padding-bottom: 11.4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.spfPrivacyMatters svg {
  margin-bottom: 1.875vw;
}

.spfPrivacyMatters h1 {
  color: white;
  font-size: 4.06vw;
  font-weight: 700;
  margin-bottom: 2.39vw;
}

.spfPrivacyMatters p {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 1.35vw;
}

.spfPrivacyMatters img {
  position: absolute;
  width: 65.8vw;
  top: -12vw;
}

@media (max-width: 768px) {
  .spfPrivacyMatters svg {
    width: 40px;
  }
  .spfPrivacyMatters h1 {
    font-size: 28px;
  }
  .spfPrivacyMatters p {
    font-size: 10px;
  }
}

.amongHighContainer {
  position: relative;
}
.amongHighContainer img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
}
.startLocally {
  text-align: center;
  font-size: 1.61vw;
  z-index: 10;
  color: #010101;
  opacity: 1;
}
.amongHigh {
  text-align: center;
  font-size: 7.395vw;
  color: #ffffff;
  text-shadow: 0px 18px 15px #d9d9d9b0;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 700;
}

.amongHighContainer .amonghightext {
  padding-top: 35.2vw;
  padding-bottom: 28.8vw;
}

@media (max-width: 768px) {
  .startLocally {
    font-size: 10px;
  }
  .amongHigh {
    font-size: 34px;
  }
}

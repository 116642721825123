.financialDesign {
  padding-bottom: 7.13vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.financialDesign img {
  width: 57.8vw;
  position: absolute;
  left: -30.8vw;
}
.financialDesign small {
  color: rgba(29, 29, 31, 0.8);
  font-size: 1.09vw;
  margin-bottom: 1.3vw;
  font-weight: 500;
}
.financialDesign h1 {
  font-size: 4.06vw;
  margin-bottom: 7.7vw;
  font-weight: 700;
  color: black;
}

.financialDesign .feature {
  display: flex;
}
.financialDesign .feature svg {
  margin-inline-end: 1.5vw;
}

.financialDesign .feature h6 {
  font-size: 1.82vw;
  font-weight: 500;
  margin-bottom: 1.56vw;
}
.financialDesign .feature p {
  margin: 0;
  color: rgba(119, 119, 123, 0.8);
  margin-bottom: 5.3vw;
}

.financialDesign .feature .moreDetails {
  font-style: 0.93vw;
}

@media (max-width: 768px) {
  .financialDesign img {
    display: none;
  }
  .financialDesign small {
    font-size: 11px;
  }
  .financialDesign h1 {
    font-size: 28px;
  }
  .financialDesign .feature {
    padding: 0 10px;
  }
  .financialDesign .feature svg {
    width: 21px;
    position: relative;
    top: -12px;
    margin-inline-end: 10px;
  }

  .financialDesign .feature h6 {
    font-size: 18px;
  }
  .financialDesign .feature p {
    font-size: 13px;
  }
  .financialDesign .feature p br {
    display: none;
  }
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroContent small {
  font-size: 1vw;
  color: white;
  margin-bottom: 12px;
  white-space: nowrap;
}
.heroContent h1 {
  font-size: 3.4vw;
  font-family: FreightBig Pro;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 30px;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .heroContent small {
    font-size: 14px;
  }
  .heroContent h1 {
    font-size: 24px;
  }
}

.homeBankPartnershipProg {
  background: white;
  padding-top: 12vw;
  padding-bottom: 9.1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeBankPartnershipProg span {
  margin-bottom: 12px;
  margin-top: 60px;
  font-weight: normal;
}

.homeBankPartnershipProg h1 {
  font-size: 3.43vw;
  font-weight: bold;
  margin-bottom: 2.03vw;
  font-family: FreightBig Pro;
  white-space: pre-line;
}

.homeBankPartnershipProg p {
  font-size: 1.36vw;
  font-weight: normal;
  font-family: Forma DJR Micro;
  color: #414141;
  margin: 0;
  white-space: pre-line;
  text-align: center;
}

@media (max-width: 768px) {
  .homeBankPartnershipProg span {
    font-weight: normal;
    margin-bottom: 12px;
    margin-top: 60px;
    font-size: 8px;
  }

  .homeBankPartnershipProg h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .homeBankPartnershipProg p {
    font-size: 11px;
    font-weight: normal;
    width: 100%;
  }
}

.laptopScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8vw;
  padding-bottom: 10.4vw;
  background: white;
}
.laptopScreen img {
  width: 72%;
}
.laptopScreen h1 {
  font-size: 4vw;
  font-weight: 700;
  margin-top: 4.6vw;
  margin-bottom: 2.9vw;
  text-align: center;
  white-space: pre-line;
}

.laptopScreen p {
  white-space: pre-line;
  color: rgba(65, 65, 65, 0.8);
  margin-bottom: 2.5vw;
  font-size: 1.3vw;
  text-align: center;
}

@media (max-width: 768px) {
  .laptopScreen h1 {
    font-size: 28px;
  }

  .laptopScreen p {
    font-size: 12px;
  }
}

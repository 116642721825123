.whitesecBtn {
  background: white;
  border-radius: 3px;
  border: 1px solid #ffffff;
  color: #2e2e37;
  font-size: 16px;
  font-weight: 500;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 37px;
  padding-right: 37px;
}
.whitesecBtn a {
  text-decoration: none;
  color: inherit;
}

.whitesecBtn.inverted {
  background: #000000;
  border: 1px solid black;
  color: white;
}

@media (max-width: 768px) {
  .whitesecBtn {
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.jobCardModal {
  border-radius: 25px;
  background-color: #fff;
  width: 70vw;
  height: 80vh;
}
.jobCardModal .jobIconMini {
  width: 100%;
}
.jobCardModal .icon {
  text-align: right;
}
.jobCardModal .postions {
  padding: 10px;
  width: 100%;
  height: 100%;
}
.jobCardModal .postions .jobsCards a {
  text-decoration: none;
}
.jobCardModal .postions .jobsCards {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 3vw;
}
.jobCardModal .postions .jobsCards .vacancies {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.jobCardModal .postions .jobsCards .vacancies .description {
  width: 48%;
  margin-bottom: 2vh;
}
.cardsContiner {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cardMODAL {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border: 1px solid #e6e4e3;
  border-radius: 8px;
  opacity: 1;
}
.jobCardModal .item {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3vw 1.5vw 2.5vw 3vw;
}
.jobCardModal .postions .jobsCards .item a {
  text-decoration: none;
}
.jobCardModal .jobIconMini .icon {
  display: flex;
  justify-content: flex-end;
}
.jobCardModal .jobIconMini .icon .maximizeContainer {
  padding: 1vw;
}
.jobInfo {
  flex-grow: 2;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.jobCardModal .postions .jobsCards .item .jobInfo span {
  font-size: 1.9vw;
  font-weight: 600;
  display: block;
}
.jobCardModal .postions .jobsCards .item .jobInfo span.Remote {
  text-align: left;
  font-size: 1.4vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}
.applayIcon {
  flex-grow: 1;
  margin: auto;
  text-align: right;
}
.EngineeringPositionsTitle {
  text-align: left;
  font-size: 4.5vw;
  font-family: FreightBig Pro;
  font-weight: 900;
  color: #000000;
  opacity: 1;
  margin-bottom: 6vw;
}

@media (max-width: 768px) {
  .jobCardModal {
    width: 70vw;
  }
}
.nojobs {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
.nojobs .nojobsavaliable span {
  text-align: center;
  font: normal normal bold 24px/32px Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.nojobs .noopenvacancies span {
  text-align: center;
  font: normal normal normal 16px/33px Forma DJR Micro;
  letter-spacing: 0.16px;
  color: #000000;
  opacity: 0.6;
}
.nojobs .wanttonotigied span {
  text-align: center;
  font: normal normal bold 16px/19px Forma DJR Micro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

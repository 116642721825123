.fintoldBlackNav .navbar {
    background: transparent;
    padding: 0;
  }
  .fintoldBlackNav .fixedNav {
    background: #000000;
  }
  
  .fintoldBlackNav .navbarContent {
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .fintoldBlackNav .navbar-brand img {
    width: 130px;
  }
  
  .fintoldBlackNav .navbarContent.withBorder {
    border-bottom: 1px solid #707070;
  }
  
  .fintoldBlackNav .nav-item {
    margin-inline-start: 27px;
    margin-inline-end: 27px;
    margin-bottom: -1px;
  }
  .fintoldBlackNav .nav-item.withBorder {
    border-bottom: 1px solid #43ec23;
  }
  .fintoldBlackNav .nav-item:first-child {
    margin-inline-start: 0px;
  }
  
  .fintoldBlackNav .nav-item:last-child {
    margin-inline-end: 0px;
  }
  
  .fintoldBlackNav .nav-link {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
    opacity: 1 !important;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .fintoldBlackNav .fixedNav .nav-link {
    color: #FFFFFF ;
  }
  .fintoldBlackNav .nav-item .nav-link.withBorder {
    color: #43ec23 !important;
  }
  
  .fintoldBlackNav .controlCont {
    display: flex;
    align-items: center;
  }
  
  .fintoldBlackNav .controlCont > * {
    margin-inline-end: 40px;
  }
  .fintoldBlackNav .controlCont > :last-child {
    margin-inline-end: 0;
  }
  .fintoldBlackNav .whitesecBtn {
    font-size: 14px;
  }
  
  .fintoldBlackNav ul.dropdown-menu {
    padding: 0;
  }
  .fintoldBlackNav ul.dropdown-menu a {
    padding: 10px;
  }
  
  .fintoldBlackNav .mobileBrand {
    display: none;
  }
  
  @media (max-width: 768px) {
    .fintoldBlackNav .navbar-brand img {
      width: 90px;
    }
    .fintoldBlackNav .navbar {
      background: #000000;
      position: relative;
      padding: 10px;
    }
    .fintoldBlackNav .navbar {
        border-bottom: 1px solid #707070;
        background-color: #FFFFFF;
        color: #000000;
        
      }
    .fintoldBlackNav .navbarContent.withBorder {
        border-bottom: 1px solid #FFFFFF;
        background-color: #FFFFFF;
      }
      
    .fintoldBlackNav .nav-link {
      color: black !important;
    }
    .fintoldBlackNav .nav-item {
      margin-inline-start: 15px;
    }
    .fintoldBlackNav .nav-item:first-child {
      margin-inline-start: 15px;
    }
  
    .fintoldBlackNav .nav-item:last-child {
      margin-inline-end: 15px;
    }
    .fintoldBlackNav .whitesecBtn,
    .fintoldBlackNav .whitesecBtn.inverted {
      background: #000000;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
    }
    .fintoldBlackNav .mobileBrand {
      display: unset;
    }
    .fintoldBlackNav .pcbrand {
      display: none;
    }
    .fintoldBlackNav .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
    .fintoldBlackNav .navbarContent.withBorder {
      border-bottom: none;
    }
    .fintoldBlackNav .nav-item.withBorder {
      border-bottom: none;
    }
    .fintoldBlackNav .nav-link {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  
.HostedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8.95vw 0;
}
.azure {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-inline-end: 6.77vw;
}
.ThePlatformIsHosting {
  letter-spacing: 0.24px;
  color: #414141;
  opacity: 1;
  font-size: 1.25vw;
  white-space: pre-line;
}
.AzureLogo {
  position: relative;
  top: -20px;
}
.HostedOnMicrosoftAzure {
  font-size: 2.91vw;
  font-weight: 700;
  letter-spacing: 0px;
  color: #000000;
  flex-wrap: wrap;
  opacity: 1;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .AzureLogo img {
    width: 30px;
  }
  .ThePlatformIsHosting {
    font-size: 8px;
  }
}

.gettingStarted {
  display: flex;
  height: 100%;
}
.gettingStarted .contentRegion {
  width: 46vw;
  padding: 1.51vw 5.93vw 4.0625vw 5.93vw;
}
.gettingStarted .ImageRegion {
  width: 54vw;
  height: 100%;
  position: relative;
}
.gettingStarted .ImageRegion img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.gettingStarted .contentRegion .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gettingStarted .contentRegion .nav .nbfilogo {
  width: 6.77vw;
}

.gettingStarted .contentRegion .header {
  margin-top: 6.66vw;
  margin-bottom: 3.4375vw;
  position: relative;
}
.gettingStarted .contentRegion .header .navLink {
  color: rgba(0, 112, 148, 0.8);
  position: absolute;
  top: -2vw;
  display: flex;
  align-items: center;
  gap: 8px;
}
.gettingStarted .contentRegion .header h1 {
  font-size: 1.8223vw;
  margin-bottom: 1.23vw;
}
.gettingStarted .contentRegion .header p {
  white-space: pre-line;
  font-size: 0.9375vw;
}

.gettingStarted .contentRegion .action small {
  color: #707070;
  font-weight: 500;
  font-size: 0.73vw;
  text-transform: uppercase;
  margin-bottom: 1.822vw;
  display: block;
}
.gettingStarted .contentRegion .action .verificationSteps .iconsWrap {
  margin-bottom: 20px;
}
.gettingStarted .contentRegion .action .actionBox {
  border: 1px solid #9babc5;
  padding: 3.125vw 2.6vw 2.08vw 2.6vw;
  position: relative;
}
.gettingStarted .contentRegion .action .actionBox .arrow {
  content: "";
  position: absolute;
  top: -0.635vw;
  border: solid 1px transparent;
  width: 1.2vw;
  height: 1.2vw;
  border-top-color: #9babc5;
  border-left-color: #9babc5;
  z-index: 1;
  transform: rotate(45deg);
  background-color: white;
}
.gettingStarted .contentRegion .action .actionBox .arrowFirst {
  content: "";
  position: absolute;
  top: -0.635vw;
  border: solid 1px transparent;
  width: 1.2vw;
  height: 1.2vw;
  border-top-color: #9babc5;
  border-left-color: #9babc5;
  z-index: 1;
  transform: rotate(45deg);
  background-color: white;
  inset-inline-start: 0.49vw;
}
.gettingStarted .contentRegion .action .actionBox .arrowSecond {
  content: "";
  position: absolute;
  top: -0.635vw;
  border: solid 1px transparent;
  width: 1.2vw;
  height: 1.2vw;
  border-top-color: #9babc5;
  border-left-color: #9babc5;
  z-index: 1;
  transform: rotate(45deg);
  background-color: white;
  inset-inline-start: 10.59vw;
}
.gettingStarted .contentRegion .action .actionBox .arrowThird {
  content: "";
  position: absolute;
  top: -0.635vw;
  border: solid 1px transparent;
  width: 1.2vw;
  height: 1.2vw;
  border-top-color: #9babc5;
  border-left-color: #9babc5;
  z-index: 1;
  transform: rotate(45deg);
  background-color: white;
  inset-inline-start: 32.3vw;
}
.gettingStarted .contentRegion .action .actionBox .arrowFourth {
  content: "";
  position: absolute;
  top: -0.635vw;
  border: solid 1px transparent;
  width: 1.2vw;
  height: 1.2vw;
  border-top-color: #9babc5;
  border-left-color: #9babc5;
  z-index: 1;
  transform: rotate(45deg);
  background-color: white;
  inset-inline-start: 21.3vw;
}
.gettingStarted .contentRegion .action .actionBox .arrowFifth {
  content: "";
  position: absolute;
  top: -0.635vw;
  border: solid 1px transparent;
  width: 1.2vw;
  height: 1.2vw;
  border-top-color: #9babc5;
  border-left-color: #9babc5;
  z-index: 1;
  transform: rotate(45deg);
  background-color: white;
  inset-inline-start: 32.3vw;
}
.gettingStarted .contentRegion .action .actionBox h6 {
  font-size: 1.3vw;
  font-weight: 500;
  margin-bottom: 0.885vw;
}
.gettingStarted .contentRegion .action .actionBox p {
  font-size: 0.83vw;
  max-width: 72%;
  margin-bottom: 0.99vw;
}
.gettingStarted .contentRegion .action .actionBox .actionBoxFooter {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 8px;
}
.gettingStarted .contentRegion .action .actionBox .actionBoxFooter a {
  color: black;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
.gettingStarted .contentRegion .action .actionBox .actionBoxFooter a.disabled {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .gettingStarted {
    justify-content: center;
  }
  .gettingStarted .contentRegion {
    width: 75vw;
  }
  .gettingStarted .ImageRegion {
    display: none;
  }
  .gettingStarted .contentRegion .nav img {
    width: 75px;
  }
  .gettingStarted .contentRegion .header h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .gettingStarted .contentRegion .header p {
    font-size: 12px;
  }
  .gettingStarted .contentRegion .action small {
    font-size: 10px;
  }
  .gettingStarted .contentRegion .action .actionBox h6 {
    font-size: 15px;
    margin-bottom: 7px;
  }
  .gettingStarted .contentRegion .action .actionBox p {
    font-size: 9px;
    margin-bottom: 10px;
  }
  .gettingStarted .contentRegion .action .actionBox .arrowFirst {
    content: "";
    position: absolute;
    top: -0.635vw;
    border: solid 1px transparent;
    width: 1.2vw;
    height: 1.2vw;
    border-top-color: #9babc5;
    border-left-color: #9babc5;
    z-index: 1;
    transform: rotate(45deg);
    background-color: white;
    inset-inline-start: 2.49vw;
  }
  .gettingStarted .contentRegion .action .actionBox .arrowSecond {
    content: "";
    position: absolute;
    top: -0.635vw;
    border: solid 1px transparent;
    width: 1.2vw;
    height: 1.2vw;
    border-top-color: #9babc5;
    border-left-color: #9babc5;
    z-index: 1;
    transform: rotate(45deg);
    background-color: white;
    inset-inline-start: 21.59vw;
  }
  .gettingStarted .contentRegion .action .actionBox .arrowThird {
    content: "";
    position: absolute;
    top: -0.635vw;
    border: solid 1px transparent;
    width: 1.2vw;
    height: 1.2vw;
    border-top-color: #9babc5;
    border-left-color: #9babc5;
    z-index: 1;
    transform: rotate(45deg);
    background-color: white;
    inset-inline-start: 59.59vw;
  }
  .gettingStarted .contentRegion .action .actionBox .arrowFourth {
    content: "";
    position: absolute;
    top: -0.635vw;
    border: solid 1px transparent;
    width: 1.2vw;
    height: 1.2vw;
    border-top-color: #9babc5;
    border-left-color: #9babc5;
    z-index: 1;
    transform: rotate(45deg);
    background-color: white;
    inset-inline-start: 41.3vw;
  }
  .gettingStarted .contentRegion .action .actionBox .arrowFifth {
    content: "";
    position: absolute;
    top: -0.635vw;
    border: solid 1px transparent;
    width: 1.2vw;
    height: 1.2vw;
    border-top-color: #9babc5;
    border-left-color: #9babc5;
    z-index: 1;
    transform: rotate(45deg);
    background-color: white;
    inset-inline-start: 60.3vw;
  }
}
.privacyparag {
  margin-top: 50px;
}
.actionBoxDeployedFlex {
  display: flex;
  flex-direction: row;
}
.addcompanystyleGettingStarteddepSVG {
  margin-top: 3.46vw;
  margin-inline-end: 1.875vw;
}
.SaveYourTime {
  width: 157%;
  margin-bottom: 3.43vw;
}
.REQUIREDACTIONS {
  margin-bottom: 39px;
  font: normal normal medium 14px/17px Forma DJR Micro;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
  opacity: 1;
}
.verificationStepsWidth {
  
}

.AddMyCompanyStylingBackGroud {
  background: #f0f2f5 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: #f0f2f5;
  padding-inline-end: 1.562vw;
  padding-inline-start: 1.562vw;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 1;
  font: normal normal medium 16px/19px Forma DJR Micro;
  letter-spacing: 0px;
  color: #4a5973;
  margin-bottom: 40px;
  margin-top: 19px;
}

.signupSideImageSaas {
  position: fixed;
  width: 55%;
  height: 100%;
}

.containerimgright {
  position: relative;
  text-align: left;
  color: #242424;
}
/* Bottom left text */
.bottom-left {
  position: fixed;
  width: 500px;
  bottom: 2%;
  margin-inline-start: 75px;
}

.gettingStarted .contentRegion .action .actionBox .actionBoxFooter.secButton{
  background-color: #f0f2f5;
  border: 1px solid #f0f2f5;
  color: #4a5973;
}

.gettingStarted .contentRegion .action .actionBox .actionBoxFooter.secButton:disabled {
  background-color: rgba(240, 242, 245, 1);
  border: 1px solid rgba(240, 242, 245, 1);
  color: rgba(74, 89, 115, 1);
  pointer-events: none;
}

.gettingStarted .contentRegion .action .actionBox .actionBoxFooter.secButton.loading {
  border: 1px solid rgba(240, 242, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  color: rgba(74, 89, 115, 1);
}
.verifyEmailActionBox {
  background: #f0f2f5 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: #f0f2f5;
  padding-inline-end: 1.562vw;
  padding-inline-start: 1.562vw;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 1;
  font: normal normal medium 16px/19px Forma DJR Micro;
  letter-spacing: 0px;
  color: #4a5973;
  margin-bottom: 40px;
  margin-top: 33px;
  cursor: pointer;
}
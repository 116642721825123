.cardsContainer {
  display: flex;
  justify-content: center;
  padding: 0 10px 0 10px;
}

.cardsContainer .column3 {
  display: none;
}

@media (max-width: 768px) {
  /* .cardsContainer .column3 {
    display: block;
  }

  .cardsContainer .column1,
  .cardsContainer .column2 {
    display: none;
  } */
}

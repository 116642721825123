.contactDetailsSec {
  background: rgba(245, 245, 245, 0.1);
  display: flex;
  flex-direction: row;
  margin-inline-start: 19.21vw;
  position: relative;
}
.contactDetailsSec h2 {
  margin-top: 3.54vw;
  font-weight: bold;
  font-size: 2.3vw;
  color: #000000;
}
.contactDetailsSecParag {
  z-index: 10;
  font-size: 1.35vw;
  font-weight: normal;
  margin-bottom: 8.75vw;
  color: #414141;
  opacity: 0.8;
  white-space: pre-line;
}
.contactSecContainer {
  border: 1px solid #e6e4e3;
  border-radius: 6px;
  opacity: 1;
  width: 30.31vw;
  padding: 3.125vw;
  margin-inline-start: 7.71vw;
}

.contactSecContainer input {
  width: 100%;
}
.contactSecContainer .inputControl {
  flex-grow: 1;
}
.contactSecContainer .countryCont {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contactSecContainer .contactDetailsBtn {
  background-color: rgba(47, 83, 235);
  color: white;
  width: 100%;
  border-radius: 3px;
  padding-top: 0.781vw;
  padding-bottom: 0.781vw;
  border: none;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.contactSecContainer .contactDetailsBtn:disabled,
.contactSecContainer .contactDetailsBtn.loading {
  /*opacity: 0.22;*/
  background-color: #cbc3e3;
  pointer-events: none;
}

@media (max-width: 768px) {
  .contactDetailsSec {
    background: rgba(245, 245, 245, 0.1);
    display: flex;
    flex-direction: column;
    margin: 0 6vw;
    position: relative;
  }
  .contactDetailsSec h2 {
    margin-top: 3.54vw;
    font-weight: 700;
    font-size: 4.3vw;
    color: #000000;
    text-align: center;
  }
  .contactDetailsSecParag {
    z-index: 10;
    font-size: 3.35vw;
    margin-bottom: 8.75vw;
    color: #414141;
    opacity: 0.8;
    white-space: pre-line;
    text-align: center;
  }
  .contactSecContainer {
    width: 80%;
  }

  .contactSecContainer .contactDetailsBtn {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

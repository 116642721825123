.fundingOptions .mainPage {
  background: black;
  padding-top: 17.9vw;
  padding-bottom: 17.8vw;
  padding-inline-start: 14vw;
  display: flex;
  flex-direction: column;
}

.fundingOptions svg {
  margin-bottom: 2.3vw;
}

.fundingOptions h1 {
  color: white;
  font-weight: 700;
  font-size: 4vw;
  margin-bottom: 2.3vw;
}

.fundingOptions p {
  color: rgba(214, 214, 214, 0.8);
  font-size: 1.2vw;
  margin: 0;
}

@media (max-width: 768px) {
  .fundingOptions .mainPage {
    padding-inline-end: 2vw;
  }
  .fundingOptions h1 {
    font-size: 28px;
  }
  .fundingOptions h1 br {
    display: none;
  }
  .fundingOptions p {
    font-size: 12px;
  }
}

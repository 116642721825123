.RetellingContainer {
  background: black;
  padding-top: 18.854vw;
  padding-bottom: 15.46vw;

  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.retellingDescription {
  text-align: left;
  font-size: 4vw;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  white-space: pre-line;
  line-height: 5vw;
  margin-bottom: 2.86vw;
}
.ourSaas {
  text-align: left;
  font-size: 1.35vw;
  letter-spacing: 0.26px;
  color: #ffffff;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .retellingDescription {
    font-style: 20px;
  }
  .ourSaas {
    font-size: 8px;
  }
}

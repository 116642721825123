.termtextContainer {
  margin-bottom: 4.7vw;
}
.termtextContainer .TermtitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.termtextContainer .TermNumber {
  opacity: 1;
  font-size: 1.66vw;
  font-weight: 500;
  margin-bottom: 1.04vw;
}
.termtextContainer .TermNumber:first-child {
  margin-inline-end: 2.1vw;
}
.termtextContainer .TermContent {
  text-align: left;
  font-size: 0.94vw;
  letter-spacing: 0.18px;
  color: #414141;
  opacity: 1;
  padding-inline-start: 3.7vw;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .termtextContainer .TermNumber {
    font-size: 14px;
  }
  .termtextContainer .TermContent {
    font-size: 11px;
    white-space: pre-line;
  }
}

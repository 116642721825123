.spfdashboardsec {
  background: rgba(245, 245, 245, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40vw;
  padding-bottom: 13.45vw;
  position: relative;
  overflow: hidden;
}

.spfdashboardsec h2 {
  font-size: 4vw;
  margin-bottom: 0.6vw;
  font-weight: 700;
  z-index: 10;
}
.spfdashboardsec h1 {
  font-size: 4vw;
  margin-bottom: 1.8vw;
  text-align: center;
  white-space: pre-line;
  font-weight: 700;
  z-index: 10;
}

.spfdashboardsec p {
  font-size: 1.35vw;
  margin-bottom: 8.75vw;
  text-align: center;
  white-space: pre-line;
  color: rgba(65, 65, 65, 0.8);
  z-index: 10;
}

.spfdashboardsec svg {
  cursor: pointer;
}

.spfdashboardsec .img1-d1 {
  width: 83.9vw;
  position: absolute;
  top: -42.5vw;
  inset-inline-start: 0;
}

.spfdashboardsec .img2-d2 {
  width: 83.9vw;
  position: absolute;
  top: -4.5vw;
  inset-inline-end: -52vw;
}

.spfdashboardsec .img3-d3 {
  width: 83.9vw;
  position: absolute;
  top: 52.5vw;
  inset-inline-end: -45vw;
}

.spfdashboardsec .img4-d4 {
  width: 83.9vw;
  position: absolute;
  top: -14.5vw;
  inset-inline-start: -50vw;
}

.spfdashboardsec .img5-d5 {
  width: 83.9vw;
  position: absolute;
  top: 48.5vw;
  inset-inline-start: -39vw;
}

@media (max-width: 768px) {
  .spfdashboardsec h2 {
    font-size: 5vw;
  }
  .spfdashboardsec h1 {
    font-size: 5vw;
  }

  .spfdashboardsec p {
    font-size: 2.5vw;
  }

  .spfdashboardsec svg {
    width: 22px;
  }
}

:root {
  --sidebarbackground: #ffffff;
  --sidebarrowhover: #f7f7f8;
  --sidebartextcolor: #4a5973;
  --white: #ffffff;
  --textcolorblack: #171717;
  --textcolorgray: #787573;
  --sidebartextcolorhover: #50b948;
  --backgroundgreen: #50b948;
  --btntextcolor: #ffffff;
  --btnbackgroundhoverfocuslightopacity: rgb(80, 185, 72, 0.1);
  --btnbackgrounddisabled: #e6e4e3;
  --btnbackgroundloading: #2b929e;
  --btnborderloading: #e6e4e3;
  --textHeaderbalck: #171717;
  --graysmalltexthints: #707070;
  --graysmalltext: #666666;
  --blacksmalltexthints: #11111a;
  --graysmalltextlow: #aabee3;
  --bluesmalltexthints: #9babc5;
  --greencircle: #49ee3d;
  --yellowcircle: #efe92c;
  --btnbackgroundblue: #0f1d42;
  --DropDownColor: #0f1d42;
  --sectionheadertext: #1d160b;
  --progressbarblue: #007094;
  --redborder: #e92121;
  --yellowborder: #dceb0d;
  --backgroundMarengo: #4a5973;
  --whitebluelight: #e8f0fe;
  --whitebrownlight: #bdbab8;
  --blackcolor: #000000;
  --AthensGray: #f0f2f5;
  --Zeus: #312820;
  --Loblolly: #b7bec9;
  --DeepCerulean: #007da3;
  --Gray: #818181;
  --Iron: #e4e4e5;
  --Zircon: #f5f8ff;
  --davygrey: #555;
  --shadeofgray: #888;
  --BokaraGrey: #23201e;
  --Lavender: #ececee;
  --redtoastbody: #f30000;
  --whitedashboard: #f1f1f1;
  --rederrortext: #f04625;
  --dropdownbuttonborderblack: #091533;
  --dropdownwhite: #eff6f5;
  --documentsectionwhite: #d2d5d8;
  --companyhomeblue: #416cdc;
  --uploadocumentgreen: #12aa3b;
  --mainbuttonwhite: #bdbbb9;
  --selecttablegray: #605454;
  --selecttableyellow: #cfc916;
  --homegreen: #4ab54c;
  --addbankgreen: #4fb947;
  --bankaccountcardblue: #103f67;
  --bankaccountcardwhite: #e1e3e5;
  --bankaccountcardlightblue: #1068b2;
  --bankaccountcardred: #f51919;
  --pendingcardsred: #e63636;
  --chinesesilver: #ccc;
  --dropdownlightblue: #59599d;
  --mainbuttonlightwhite: #dedddc;
  --bigcountrywhite: #eff3f6;
  --inputegreen: #00bb1f;
  --sectionboxgreen: #7bca75;
  --sectioncardwhite: #f6f7fb;
  --stepmodalwhite: #f8f8f8;
  --selecttableblack: #252527;
  --cellsred: #ef4828;
  --stepmodallightwhite: #f6f6f6;
  --selecttablegray: #a1a3b3;
  --selecttablelightgray: #b7b7b7;
  --selecttablegreen: #15cd72;
  --selecttablegreenborder: #0fc169;
  --cellblack: #161616;
  --cellsyellow: #dbd314;
  --cellsgray: #a3a5b5;
  --dashboardyellow: #dbad14;
  --companyhomeblue: #1b3270;
  --dashboardcompanycardlightblue: #4f83eb;
  --dashboardcompanycardwhite: #e1e1e1;
  --linksholderwhite: #e4e8ec;
  --emailverifiactionblack: #1d1c1c;
  --loginblack: #241c15;
  --calculationboxwhite: #c7c7c7;
  --logingray: #656565;
  --loginlightblue: #0077b5;
  --logginwhite: #dddddd;
  --loginlightblue: #357ce5;
  --notificationblue: #213468;
  --searchgray: #73706e;
  --searchwhite: #f5f4f3;
  --searchblue: #2f53eb;
  --sidebarblue: #1452f9;
  --resetpasswordred: #e00000;
  --invoicesblue: #007acc;
  --invoicestextareagray: #9cacc5;
  --emptystatewhite: #e3e7ec;
  --valuesgray: #8b8fb0;
  --financialdetailsgray: #53777a;
  --addbankaccountgreen: rgba(79, 185, 71, 1);
  --addbankaccountblack6: rgba(0, 0, 0, 0.6);
  --signoutlishtblack2: rgba(0, 0, 0, 0.2);
  --dropdowngray7: rgba(80, 185, 72, 0.07);
  --mainbuttonblack1: rgba(15, 29, 66, 0.1);
  --secondarybuttonwhite: rgba(240, 242, 245, 1);
  --secondarybuttonwhitebackground: rgba(255, 255, 255, 1);
  --bigcountryblack4: rgba(23, 23, 23, 0.4);
  --documentsectiogray8: rgba(112, 112, 112, 0.8);
  --selecttablegray7: rgba(239, 246, 245, 0.7);
  --cellsblack3: rgba(74, 89, 115, 0.3);
  --companyhomeblack12: rgba(0, 0, 0, 0.12);
  --companyhomeblack14: rgba(0, 0, 0, 0.14);
  --liksholdergray1: rgba(183, 190, 201, 0.1);
  --liksholderwhite: rgba(228, 228, 229, 1);
  --gettingstartedblue8: rgba(0, 112, 148, 0.8);
  --supplierinvitationblack8: rgba(36, 28, 21, 0.8);
  --notificationsblue8: rgba(15, 29, 66, 0.8);
  --notificationgray1: rgba(88, 88, 88, 1);
  --notificationwhite: rgba(245, 248, 255, 1);
  --notificationblue10: rgba(0, 125, 163, 1);
  --notificationblue7: rgba(247, 247, 248, 0.7);
  --companyholderblue10: rgba(79, 131, 235, 1);
  --emptystateblack8: rgba(49, 40, 32, 0.8);
  --monthsslidergreen98: rgba(123, 202, 117, 0.98);
  --verificationdocumentgreen10: rgba(80, 185, 72, 1);
  --verificationdocumentblue6: rgba(15, 29, 66, 0.6);
  --verificationdocumentblack05: rgba(80, 185, 72, 0.05);
  --btnbackgroundgreen: #50b948;
}

Provide sufficient contrast against white background a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

body,
html,
#root {
  height: 100%;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

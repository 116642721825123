.expandableCard {
  width: 35vw;
  height: 42vw;
  border-radius: 8px;
  position: relative;
  margin-bottom: 2vw;
  margin-inline-end: 2vw;
  pointer-events: none;
}

.expandableCard.clickable {
  pointer-events: unset;
  cursor: pointer;
}

.expandableCard.upperOffset {
  margin-top: 14vw;
}

.expandableCard .cardImage {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.expandableCard .cardContent {
  padding: 4vw 3vw 2vw 3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cardContent small {
  color: #000000;
  font-size: 0.8vw;
  font-weight: 500;
  margin-bottom: 1.3vw;
  display: block;
}
.cardContent h1 {
  font-size: 2.3vw;
  font-family: FreightBig Pro;
  font-weight: 500;
}

.cardContent .cardcontrols {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardContent .cardcomingsoon {
  padding: 9px 22px;
  color: white;
  background: black;
  border-radius: 19px;
  font-size: 0.8vw;
  font-weight: 500;
}

.cardContent .maximizeBtn {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  cursor: pointer;
}
.maximizeBtn.disabled {
  pointer-events: none;
  cursor: default;
}

@media (max-width: 768px) {
  .cardContent small {
    font-size: 10px;
  }
  .cardContent .maximizeBtn svg {
    width: 12px;
    height: 12px;
  }
  .cardContent .maximizeBtn {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .cardContent .cardcomingsoon {
    font-size: 9px;
    padding: 5px 10px;
  }
}

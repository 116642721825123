.homepage .HomeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 9.58vw;
  margin-bottom: 6.6vw;
}
.homepage .HomeSection * {
  white-space: pre-line;
  text-align: center;
}
.homepage .HomeSection svg {
  margin-bottom: 2.64vw;
}
.homepage .HomeSection h1 {
  font-size: 4vw;
  color: black;
  font-weight: 700;
  margin-bottom: 1.56vw;
}
.homepage .HomeSection p {
  font-weight: 500;
  font-size: 1vw;
  color: #1d1d1f;
  opacity: 0.8;
}
.homepage .cardMargin {
  margin-bottom: 40px;
  margin-inline-end: 40px;
}

@media (max-width: 768px) {
  .homepage .HomeSection svg {
    width: 23px;
  }
  .homepage .HomeSection h1 {
    font-size: 28px;
  }
  .homepage .HomeSection p {
    font-size: 12px;
  }
}

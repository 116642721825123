.stylingtitlecenternbficomp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.stylingtitlecenternbficomp .aligncenteallnbficomp .stylingOurSolutionsnbfisstylecomp h6 {
  font-size: 12px;
  font-weight: 600;
  color: #50B948;
}

.stylingtitlecenternbficomp .col-3 {
  width: 100%;
}

.aligncenteallnbficomp {
  text-align: center;
  margin-top: 35px;
}

.stylingOurSolutionsnbfisstylecomp {
  text-align: center;
  margin-inline-start: 18px;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 1.44px;
  color: var(--backgroundgreen);
  text-transform: uppercase;
  opacity: 1;
}

.Choosewhichclientstylenbfistylecomp {
  text-align: center;
  width: 100%;
  letter-spacing: 0px;
  color: var(--textcolorblack);
  opacity: 1;
  margin-bottom: 2.44vw;
}

.Choosewhichclientstylenbfistylecomp h1 {
  font-weight: 600 !important;
}

.stylingall11111nbfiicomp {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  flex-direction: column;
  padding-inline-start: 1px;
  margin-top: 37px;
}

.stylingall11111nbfiicomp li.disabled {
  cursor: default;
  pointer-events: none;
}

.addingcompboxshadoincardnbfiicomp {
  box-shadow: 0px 7px 11px var(--whitebluelight);
  border: 1px solid var(--Iron);
  border-radius: 3px;
  margin-bottom: 1.3vw;
  cursor: pointer;
  margin-inline-start: 1.5625vw;
}

.addingcompboxshadoincardnbfiicomp .card-body {
  padding: 4.35vh 2.34vw 2.4vh;
}

.addingcompboxshadoincardnbfiicomp .card-body .stylingsupplayChainnbfi {
  margin-bottom: 15px;
}

.addingcompboxshadoincardnbfiicomp .card-body span {
  font-size: 18px;
  font-weight: bold;
  color: #171717;
}

.addingcompboxshadoincardnbfiicomp .card-body .paragraphstylingcomp p {
  font-size: 16px;
  font-weight: 400;
  color: #312820;
}

.addingcompboxshadoincardnbfiicomp .card-body .logoContainer {
  width: 70px;
  height: 70px;
  border: 1px solid #EFF6F5;
  border-radius: 18px;
  padding: 17px;
}

.coloringDocumentneedednbfistylecomp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: var(--progressbarblue);
  opacity: 1;
  margin-bottom: 33px;
}

.coloringDocumentneedednbfistylecomp a svg {
  margin-inline-start: 5px;
}

.styylingimageframenbfiframecomp {
  border-radius: 18px;
  opacity: 1;
  margin-top: 10px;
}

.paragraphstylingcomp {
  text-align: start;
  letter-spacing: 0px;
  color: var(--Zeus);
  opacity: 1;
  width: 85%;
  height: 200%;
}
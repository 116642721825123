.fintoldCard {
  padding-top: 10vw;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fintoldCard h1 {
  font-size: 4vw;
  color: white;
  margin-top: 2.1vw;
  margin-bottom: 2.6vw;
  white-space: pre-line;
  text-align: center;
}

.fintoldCard p {
  color: rgba(243, 243, 243, 0.8);
  margin-bottom: 2.6vw;
  text-align: center;
  font-size: 1.3vw;
}

.fintoldCard .comingsoon {
  color: rgba(35, 35, 236, 1);
  display: flex;
  align-items: center;
  font-size: 1vw;
  margin-bottom: 4.2vw;
}

.fintoldCard .comingsoon svg {
  margin-inline-end: 12px;
}
.fintoldCard .fintoldcardImg {
  width: 72%;
}

@media (max-width: 768px) {
  .fintoldCard h1 {
    font-size: 28px;
  }

  .fintoldCard p {
    font-size: 13px;
  }

  .fintoldCard .comingsoon {
    font-size: 10px;
  }
  .fintoldCard .comingsoon svg {
    width: 20px;
  }
  .fintoldCard .cardSvg {
    width: 50px;
  }
}

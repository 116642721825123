.solutionContainer {
  padding-top: 7.55vw;
  padding-bottom: 9vw;
  padding-left: 19.21875vw;
  padding-right: 19.21875vw;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.empower {
  text-align: left;
  letter-spacing: 0.24px;
  color: #414141;
  opacity: 1;
  white-space: pre-line;
  font-size: 1.25vw;
  margin-bottom: 1.5625vw;
}
.moreDetails {
  text-align: left;
  font-size: 1vw;
  font-weight: 700;
  letter-spacing: 0.19px;
  color: #2323ec;
  opacity: 1;
  margin-inline-end: 4px;
}
.solutions {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 2.91vw;
  white-space: pre-line;
  font-weight: 700;
  line-height: 3.125vw;
}
.moreDetailsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  text-decoration: none;
}

@media (max-width: 768px) {
  .solutionContainer {
    padding-left: 15vw;
    padding-right: 15vw;
    align-items: flex-start;
  }
  .solutions {
    font-style: 20px;
    line-height: 3.5vw;
  }
  .empower {
    font-size: 8px;
  }
  .moreDetails {
    font-size: 7px;
  }
  .moreDetailsContainer img {
    width: 10px;
    height: 10px;
  }
}

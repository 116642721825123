.careerPostLanding {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.careesPostContainer {
  margin-top: 80px;
}
.apply {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}
.apply button {
  color: white;
  background-color: #000;
  padding: 10px 20px;
  border: none;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
}
.apply button:hover {
  outline: 1px solid black;
  background-color: transparent;
  color: #000;
}
.apply ul {
  list-style-type: none;
}
.apply ul li {
  color: #2f53eb;
  font-size: 20px;
}
hr {
  margin: 50px 0 !important;
}
.Jobdesc h3,
.Jobreq h3 {
  font-size: 20px !important;
  font-weight: 800;
}
.Jobdesc {
  width: 51vw;
  margin-bottom: 3.64vw;
}
.Jobreq {
  width: 51vw;
}
.Jobdesc p,
.Jobreq p {
  margin-top: 20px;
}
.applyForm {
  margin-top: 100px;
}
.formIcon h2 {
  font-size: 4.5vw;
  font-weight: bold;
  color: #000;
  max-width: 100%;
  margin: 20px 0;
}
.formIcon span {
  font-size: 1.3vw;
}
.careesPostContainer ul {
  list-style-type: disc;
  padding: 0;
}
.careesPostContainer li {
  margin-bottom: 15px;
}
.careesPostContainer li {
  margin-left: 2px;
  margin-right: 2px;
}
.Share {
  cursor: pointer;
}
.ApplyButton {
  background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 3px;
  opacity: 1;
  width: 146px;
  text-align: center;
  margin-inline-end: 1.7vw;
}
.ApplyButton a {
  text-decoration: none;
  color: inherit;
}
.ApplyButton :hover {
  color: #fff !important;
  text-decoration: none;
}
.JobTitle h2 {
  font-weight: 700;
  font-family: "FreightBig Pro";
  font-size: 3.43vw;
  margin-top: 2.1vw;
}
.loctation {
  margin: 2vw 0;
}
Modal {
  margin: auto;
}
.applyForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
#jobpostSec span {
  display: inline-block;
}
.bolded {
  font-weight: 500;
}

@media (max-width: 768px) {
  .formIcon h2 {
    font-size: 40px;
  }
  .formIcon span {
    font-size: 17px;
  }
  .Jobdesc {
    width: 90vw;
  }
  .Jobreq {
    width: 90vw;
  }
  .JobTitle h2 {
    font-size: 32px;
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .careesPostContainer ul {
    margin-left: 5px;
  }
}

.bankDashboardSec {
  background: rgba(245, 245, 245, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 46vw;
  padding-bottom: 13.45vw;
  position: relative;
  overflow: hidden;
}

.bankDashboardSec h2 {
  z-index: 10;
  font-size: 4vw;
  margin-bottom: 0.6vw;
  font-weight: bold;
  fill: #f5f5f5;
  border: #00000029;
  fill-opacity: 0.4;
}
@supports ((text-stroke: 2px black) or (-webkit-text-stroke: 2px black)) {
  .bankDashboardSec h2.outline {
    color: transparent;
    -webkit-text-stroke: 1px black;
    text-stroke: 1px black;
    text-shadow: none;
  }
}

.bankDashboardSec h1 {
  z-index: 10;
  font-size: 4vw;
  margin-bottom: 1.8vw;
  text-align: center;
  white-space: pre-line;
  font-weight: bold;
}

.bankDashboardSec p {
  z-index: 10;
  font-size: 1.31vw;
  font-weight: normal;
  margin-bottom: 8.75vw;
  text-align: center;
  white-space: pre-line;
  color: rgba(65, 65, 65, 0.8);
  opacity: 0.8;
}

.bankDashboardSec svg {
  cursor: pointer;
}

.bankDashboardSec .img1-p1 {
  width: 83.9vw;
  position: absolute;
  top: -39.5vw;
  inset-inline-start: -3.5vw;
}

.bankDashboardSec .img2-p2 {
  width: 83.9vw;
  position: absolute;
  top: -4.5vw;
  inset-inline-end: -52vw;
}

.bankDashboardSec .img3-p4 {
  width: 83.9vw;
  position: absolute;
  top: 52.5vw;
  inset-inline-end: -45vw;
}

.bankDashboardSec .img4-p3 {
  width: 83.9vw;
  position: absolute;
  top: -6.5vw;
  inset-inline-end: 67vw;
}

.bankDashboardSec .img5-p4 {
  width: 83.9vw;
  position: absolute;
  top: 49.5vw;
  inset-inline-start: -38vw;
}

@media (max-width: 768px) {
  .bankDashboardSec h2 {
    font-size: 5vw;
  }
  .bankDashboardSec h1 {
    font-size: 5vw;
  }

  .bankDashboardSec p {
    font-size: 2.95vw;
  }

  .bankDashboardSec svg {
    width: 22px;
  }
}

.smallUploadDocument {
  margin-bottom: 40px;
}
.smallUploadDocument .dragAndDropLabel {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.smallUploadDocument .dragAndDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.smallUploadDocument .upload {
  height: 152px;
  border: 2px dashed #e4e4e5;
  border-radius: 6px;
  padding: 2vw 3vw;
  background-color: white;
  padding-top: 15px;
}
.smallUploadDocument .error {
  height: 152px;
  border: 2px dashed #e63636;
  padding: 15px 17px;
  border-radius: 6px;
  background-color: white;
}
.smallUploadDocument .upload .uploadingProcess {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.smallUploadDocument .upload .uploadingProcess svg.documentIcon {
  position: absolute;
  cursor: pointer;
  top: -15px;
  inset-inline-start: 30%;
  width: 40px;
  height: 40px;
}
.smallUploadDocument .upload .uploadingProcess .document {
  position: relative;
  width: 34%;
  height: 100%;
  box-shadow: 0px 7px 11px #e8f0fe;
  border: 1px solid #e4e4e5;
  border-radius: 3px;
}
.smallUploadDocument .upload .uploadingProcess .document .page {
  max-width: 100% !important;
  height: 100% !important;
}
.smallUploadDocument .upload .uploadingProcess .document canvas {
  max-width: 100% !important;
  height: 100% !important;
}
.smallUploadDocument .upload .uploadingProcess .document.completed {
  border: 3px solid #12aa3b;
  border-radius: 3px;
  opacity: 1;
}
.smallUploadDocument .upload .uploadingProcess div.img {
  width: 34%;
  height: 100%;
  position: relative;
  box-shadow: 0px 7px 11px #e8f0fe;
  border: 1px solid #e4e4e5;
  border-radius: 3px;
}
.smallUploadDocument .upload .uploadingProcess img {
  max-width: 100%;
  max-height: 100%;
}
.smallUploadDocument .upload .uploadingProcess .img.completed {
  border: 3px solid #12aa3b;
  opacity: 1;
}
.smallUploadDocument .upload .uploadingProcess .progressContainer {
  width: 38%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.smallUploadDocument
  .upload
  .uploadingProcess
  .progressContainer
  svg.uploadIcon {
  flex-grow: 1;
  flex-shrink: 3;
  fill: #e4e4e5;
}
.smallUploadDocument .upload .uploadingProcess .uploadProgress {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 3;
}
.smallUploadDocument
  .upload
  .uploadingProcess
  .uploadProgress
  span:first-child {
  font-size: 14px;
  color: #4a5973;
  font-weight: 600;
  display: block;
}
.smallUploadDocument
  .upload
  .uploadingProcess
  .uploadProgress
  span:nth-child(2) {
  font-size: 12px;
  color: #9babc5;
}
.smallUploadDocument .upload .uploadingProcess .uploadProgress .remove {
  color: #e63636;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}
.smallUploadDocument .upload .uploadingProcess .uploadProgress .remove svg {
  margin-inline-end: 8.4px;
}
.smallUploadDocument .dragAndDrop .firstContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
  height: 100%;
}
.smallUploadDocument .dragAndDrop .secondContainer {
  margin-top: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100%;
}
.smallUploadDocument .dragAndDrop .secondContainer input {
  width: 200px;
}
.smallUploadDocument .dragAndDrop .firstContainer svg {
  width: 35px;
  height: 35px;
  fill: #e4e4e5;
  margin: 1vw 0;
  flex-shrink: 0;
}
.smallUploadDocument div.dragAndDrop .firstContainer span:nth-child(2) {
  font-size: 14px;
  font-weight: 600;
  color: #4a5973;
  opacity: 1;
  white-space: nowrap;
}
.smallUploadDocument div.dragAndDrop .firstContainer span:nth-child(3) {
  font-size: 12px;
  color: #9babc5;
  opacity: 1;
}
.smallUploadDocument div.dragAndDrop span:nth-last-child(2) {
  font-size: 14px;
  color: #9babc5;
  opacity: 1;
}
.smallUploadDocument div.dragAndDrop span.danger {
  font-size: 14px;
  font-weight: 600;
  color: #e63636;
  opacity: 1;
}
.smallUploadDocument div.dragAndDrop button {
  background-color: #f0f2f5;
  color: #4a5973;
  font-weight: 500;
  border: none;
  padding: 10px 21px;
  margin-bottom: 6px;
  border-radius: 2px;
}
.smallUploadDocument .dragAndDrop input {
  font-size: 16px;
  display: block;
  border: none;
  text-transform: none;
  opacity: 0;
}
.smallUploadDocument span.errorMessage {
  font-size: 14px;
  font-weight: 500;
  color: #e92121;
}

@media (max-width: 768px) {
  .smallUploadDocument .dragAndDrop .firstContainer {
    width: 40%;
    margin-top: 40px;
  }
  .smallUploadDocument div.dragAndDrop .firstContainer span:nth-child(2) {
    font-size: 10px;
  }
  .smallUploadDocument .dragAndDrop .secondContainer {
    margin-top: 40px;
  }
  .smallUploadDocument div.dragAndDrop button {
    font-size: 10px;
    padding: 7px 15px;
  }
  .smallUploadDocument div.dragAndDrop span:nth-last-child(2) {
    font-size: 10px;
    white-space: nowrap;
  }
  .smallUploadDocument
    .upload
    .uploadingProcess
    .uploadProgress
    span:first-child {
    font-size: 10px;
  }
  .smallUploadDocument
    .upload
    .uploadingProcess
    .uploadProgress
    span:nth-child(2) {
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.spfclientsareasec {
  background: rgba(245, 245, 245, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 26vw;
  padding-bottom: 13.45vw;
  position: relative;
  overflow: hidden;
}

.spfclientsareasec h2 {
  z-index: 10;
  font-size: 4vw;
  margin-bottom: 0.6vw;
  font-weight: 700;
}
.spfclientsareasec h1 {
  z-index: 10;
  font-size: 4vw;
  margin-bottom: 1.8vw;
  text-align: center;
  white-space: pre-line;
  font-weight: 700;
}

.spfclientsareasec p {
  z-index: 10;
  font-size: 1.35vw;
  margin-bottom: 8.75vw;
  text-align: center;
  white-space: pre-line;
  color: rgba(65, 65, 65, 0.8);
}

.spfclientsareasec svg {
  cursor: pointer;
}

.spfclientsareasec .img1-c1 {
  width: 83.9vw;
  position: absolute;
  top: -56.5vw;
  inset-inline-start: -3.5vw;
}

.spfclientsareasec .img2-c2 {
  width: 83.9vw;
  position: absolute;
  top: -4.5vw;
  inset-inline-end: -52vw;
}

.spfclientsareasec .img3 {
  width: 83.9vw;
  position: absolute;
  top: 52.5vw;
  inset-inline-end: -45vw;
}

.spfclientsareasec .img4 {
  width: 83.9vw;
  position: absolute;
  top: -30.5vw;
  inset-inline-end: 67vw;
}

.spfclientsareasec .img5 {
  width: 83.9vw;
  position: absolute;
  top: 34.5vw;
  inset-inline-start: -39vw;
}

#factSec .chooseSol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.927083vw;
  border-bottom: 1px solid #CCCCCC;
  height: 8.518518518vh;
  width: 100%;
  padding-inline-start: 14.0625vw;
  margin-bottom: 5.625vw;
}

#factSec .chooseSol span{
  color: #414141;
  opacity: 0.8;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#factSec .chooseSol span.active{
  color: #2323EC;
  opacity: 0.8;
  border-bottom: 2px solid #2323EC;
  margin-bottom: -2px;
}
#factSec .chooseSol span:first-child {
  font-size: 0.833333vw;
  cursor: default;
  pointer-events: none;
}
#factSec .chooseSol span:not(:first-child) {
  font-size: 0.9375vw;
  cursor: pointer;
}
@media (max-width: 768px) {
  .spfclientsareasec h2 {
    font-size: 5vw;
  }
  .spfclientsareasec h1 {
    font-size: 5vw;
  }

  .spfclientsareasec p {
    font-size: 2.5vw;
  }

  .spfclientsareasec svg {
    width: 22px;
  }
}

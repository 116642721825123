.stylingOurSolutionsAct {
  text-align: left;
  font: normal normal medium 16px/19px Forma DJR Micro;
  letter-spacing: 0.24px;
  color: #000000;
  background-color: transparent;
  opacity: 1;
  margin-bottom: 1.302vw;
}
@media (max-width: 700px) {
  .stylingOurSolutionsAct {
    font-size: 16px;
    margin-inline-start: -275px;
    margin-top: 30px;
    width: 210px;
  }
}
.makebackTranspert {
  background-color: transparent;
}
.HelpBuyeresSol h4 {
  text-align: left;
  font: normal 2.7vw FreightBig Pro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 1.614vw;
}
@media (max-width: 700px) {
  .HelpBuyeresSol h4 {
    font-size: 38px;
    margin-inline-start: -279px;
    margin-top: 30px;
    width: 350px;
  }
}
.ActivateSolutionMicro {
  text-align: left;
  font: normal normal normal 1.718vw Forma DJR Micro;
  letter-spacing: 0.23px;
  color: #2a2a2a;
  opacity: 0.8;
  margin-bottom: 6.0833vw;
}
@media (max-width: 700px) {
  .ActivateSolutionMicro {
    font-size: 25px;
    margin-inline-start: -275px;
    margin-top: 30px;
    width: 286px;
  }
}
.butnActivateSolWebsaas {
  background-color: #0f1d42;
  border-radius: 3px;
  border: #0f1d42;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  text-align: center;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #eff6f5;
  opacity: 1;
}
@media (max-width: 700px) {
  .butnActivateSolWebsaas {
    font-size: 16px;
    margin-inline-start: -279px;
    margin-top: 19px;
    width: 170px;
  }
}
.Imagewebsaas {
  margin-top: -98.645vw;
  margin-inline-start: -19.68vw;
  overflow: hidden;
}
@media (max-width: 700px) {
  .Imagewebsaas {
    margin-top: -1300px;
    margin-inline-start: -648px;
    margin-bottom: 10px;
  }
}
.activateSolOverrideOverFlowY {
  overflow-y: hidden;
  width: 100%;
}
@media (max-width: 700px) {
  .activateSolOverrideOverFlowY {
    height: 124%;
    overflow-y: hidden;
  }
}
.StyleMoreDetailsAct,
.StyleMoreDetailsAct:hover {
  text-decoration: underline;
  font: normal normal 600 1.093vw Poppins;
  letter-spacing: 0px;
  color: #007094;
  flex-direction: row;
  display: flex;
  margin-inline-start: 1.562vw;
  margin-top: 10px;
  cursor: pointer;
}
@media (max-width: 700px) {
  .StyleMoreDetailsAct,
  .StyleMoreDetailsAct:hover {
    font-size: 14px;
    margin-inline-start: -79px;
    margin-top: 30px;
    width: 200px;
  }
}
.ActivateSolutionSVGWeb {
  margin-inline-start: 6px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
}
.ActivateSolutionFlex {
  flex-direction: row;
  display: flex;
}
.imagetopWebsaasActive {
  margin-top: -78.71vw;
}
.mainModalActivateWebsaas.RemoveTransperent.modal-backdrop {
  background-color: transparent;
  opacity: 1;
}
.mainModalActivateWebsaas.RemoveTransperent.modal-backdrop.fade.show {
  background-color: transparent;
  opacity: 1;
}

.mainModalActivateWebsaas .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8f0fe;
  padding-bottom: 15px;
  margin: 2.822vw 6.208vw 2.604vw 6.208vw;
}

.mainModalActivateWebsaas .header .dismiss {
  display: flex;
  align-items: center;
  color: rgba(0, 112, 148, 1);
  font-size: 15px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  outline: none;
}

.mainModalActivateWebsaas .header .dismiss svg {
  margin-inline-end: 5px;
}

.mainModalActivateWebsaas .header .progSaved {
  display: flex;
  align-items: center;
  color: #787573;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
}

.mainModalActivateWebsaas .header .progSaved:first-child {
  margin-inline-start: 5px;
}

.mainModalActivateWebsaas .modal-content {
  border: none;
}

.mainModalActivateWebsaas .modal-header {
  border-bottom: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1rem 0 1rem;
}
.mainModalActivateWebsaas .modal-header small {
  color: #787573;
  font-size: 14px;
}
.mainModalActivateWebsaas .modal-header h1 {
  font-size: 1.8229vw;
  color: #171717;
  margin-bottom: 1.041vw;
  font-family: Raleway;
  font-weight: 700;
}
.mainModalActivateWebsaas .modal-header p {
  color: #312820;
  font-weight: 400;
  width: 70%;
}
.mainModalActivateWebsaas .modal-header .verificationSteps {
  margin: 1.0416vw 0 2.0833vw 0;
}
.mainModalActivateWebsaas .modal-body {
  padding: 0 1rem 1rem 1rem;
}
.mainModalActivateWebsaas .modal-body .bodyHeader h3 {
  font-weight: 500;
  color: #23201e;
  font-size: 1.302vw;
  margin-bottom: 1.04166vw;
}
.mainModalActivateWebsaas .modal-body .bodyHeader p {
  font-weight: 400;
  color: #312820;
  font-size: 16px;
  margin-bottom: 1.822vw;
  width: 55%;
}

.mainModalActivateWebsaas .confirmTab {
  display: flex;
  margin-bottom: 1.822vw;
  align-items: center;
}

.mainModalActivateWebsaas .ConfirmHead {
  color: #171717;
  font-size: 1.3020vw;
  font-weight: 500;
  margin: 0 0 1.5625vw 0;
}

.mainModalActivateWebsaas .ConfirmSubHead {
  color: #312820;
  font-size: 16px;
  font-weight: 400;
}
.mainModalActivateWebsaas .confirmTab img {
  background-color: #eff6f5;
  border-radius: 50%;
  margin-inline-end: 2.604vw;
}

.stylingOurSolutionsActn {
  text-align: left;
  font: normal normal medium 16px/19px Forma DJR Micro;
  letter-spacing: 0.24px;
  color: #000000;
  background-color: transparent;
  opacity: 1;
  margin-bottom: 1.302vw;
}
@media (max-width: 700px) {
  .stylingOurSolutionsActn {
    font-size: 16px;
    margin-inline-start: -275px;
    margin-top: 30px;
    width: 210px;
  }
}
.makebackTranspertn {
  background-color: transparent;
}
.HelpBuyeresSoln h4 {
  text-align: left;
  font: normal normal medium 45px/52px FreightBig Pro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 1.614vw;
  width: 380px;
}
@media (max-width: 700px) {
  .HelpBuyeresSoln h4 {
    font-size: 38px;
    margin-inline-start: -279px;
    margin-top: 30px;
    width: 350px;
  }
}
.ActivateSolutionMicron {
  text-align: left;
  font: normal normal normal 1.718vw Forma DJR Micro;
  letter-spacing: 0.23px;
  color: #2a2a2a;
  opacity: 0.8;
  margin-bottom: 6.0833vw;
  width: 565px;
}
@media (max-width: 700px) {
  .ActivateSolutionMicron {
    font-size: 25px;
    margin-inline-start: -275px;
    margin-top: 30px;
    width: 286px;
  }
}
.butnActivateSolWebsaasn {
  background-color: #0f1d42;
  border-radius: 3px;
  border: #0f1d42;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  text-align: center;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #eff6f5;
  opacity: 1;
}
.butnActivateSolWebsaasndisable {
  background-color: #9babc5;
  border-radius: 3px;
  border: #9babc5;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  text-align: center;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #eff6f5;
  opacity: 0.75;
  pointer-events: none;
}
@media (max-width: 700px) {
  .butnActivateSolWebsaasn {
    font-size: 16px;
    margin-inline-start: -279px;
    margin-top: 19px;
    width: 170px;
  }
}
@media (max-width: 700px) {
  .Imagewebsaasn {
    margin-top: -1300px;
    margin-inline-start: -648px;
    margin-bottom: 10px;
  }
}

.ActivateSolutionSVGWebn {
  margin-inline-start: 6px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
}
.ActivateSolutionFlexn {
  flex-direction: row;
  display: flex;
}
.mainModalActivateWebsaasn .modal {
  background-image: url("ActivateSolutionCenter.webp");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  width: 102%;
}

.mainModalActivateWebsaasn .headern {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8f0fe;
  padding-bottom: 15px;
  margin: 2.822vw 6.208vw 2.604vw 6.208vw;
}
.mainModalActivateWebsaasn .headern .dismissn {
  display: flex;
  align-items: center;
  color: rgba(0, 112, 148, 1);
  font-size: 15px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  outline: none;
}

.mainModalActivateWebsaasn .headern .dismissn svg {
  margin-inline-end: 5px;
}

.mainModalActivateWebsaasn .headern .progSaved {
  display: flex;
  align-items: center;
  color: #787573;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
}

.mainModalActivateWebsaasn .headern .progSaved:first-child {
  margin-inline-start: 5px;
}

.mainModalActivateWebsaasn .modal-content {
  border: none;
}

.mainModalActivateWebsaasn .modal-header {
  border-bottom: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1rem 0 1rem;
}
.mainModalActivateWebsaasn .modal-header small {
  color: #787573;
  font-size: 14px;
}
.mainModalActivateWebsaasn .modal-header h1 {
  font-size: 1.8229vw;
  color: #171717;
  margin-bottom: 1.041vw;
  font-family: Raleway;
  font-weight: 700;
}
.mainModalActivateWebsaasn .modal-header p {
  color: #312820;
  font-weight: 400;
  width: 70%;
}
.mainModalActivateWebsaasn .modal-header .verificationSteps {
  margin: 1.0416vw 0 2.0833vw 0;
}
.mainModalActivateWebsaasn .modal-body {
  padding: 0 1rem 1rem 1rem;
}
.mainModalActivateWebsaasn .modal-body .bodyHeader h3 {
  font-weight: 500;
  color: #23201e;
  font-size: 1.302vw;
  margin-bottom: 1.04166vw;
}
.mainModalActivateWebsaasn .modal-body .bodyHeader p {
  font-weight: 400;
  color: #312820;
  font-size: 16px;
  margin-bottom: 1.822vw;
  width: 55%;
}

.mainModalActivateWebsaasn .confirmTab {
  display: flex;
  margin-bottom: 1.822vw;
  align-items: center;
}

.mainModalActivateWebsaasn .ConfirmHead {
  color: #171717;
  font-size: 1.302vw;
  font-weight: 500;
  margin: 0 0 1.5625vw 0;
}

.mainModalActivateWebsaasn .ConfirmSubHead {
  color: #312820;
  font-size: 16px;
  font-weight: 400;
}
.mainModalActivateWebsaasn .confirmTab img {
  background-color: #eff6f5;
  border-radius: 50%;
  margin-inline-end: 2.604vw;
}
.StyleMoreDetailsActn,
.StyleMoreDetailsActn:hover {
  text-decoration: underline;
  font: normal normal 600 1.093vw Poppins;
  letter-spacing: 0px;
  color: #007094;
  flex-direction: row;
  display: flex;
  margin-inline-start: 1.562vw;
  margin-top: 10px;
  cursor: pointer;
}
@media (max-width: 700px) {
  .StyleMoreDetailsActn,
  .StyleMoreDetailsActn:hover {
    font-size: 14px;
    margin-inline-start: -79px;
    margin-top: 30px;
    width: 200px;
  }
}

.BankWorkflowSec {
  background: rgba(245, 245, 245, 0.1);
  display: flex;
  flex-direction: column;
  margin-inline-start: 19.21vw;
  padding-top: 5.7vw;
  padding-bottom: 4.11vw;
  position: relative;
  overflow: hidden;
}
.BankWorkflowSec h2 {
  font-weight: bold;
  font-size: 2.9vw;
  margin-bottom: 8.75vw;
  color: #000000;
}
.TextFlexSubTitle {
  display: flex;
  flex-direction: row;
}
.TextFlex {
  display: flex;
  flex-direction: column;
}
.TexFlexColumn {
  display: flex;
  flex-direction: row;
}
.BankWorkflowSec p {
  z-index: 10;
  font-size: 1.25vw;
  font-weight: normal;
  margin-bottom: 8.75vw;
  margin-inline-start: 5.2vw;
  margin-top: 10px;
  white-space: pre-line;
  color: #414141;
  opacity: 0.9;
}
.dotNum {
  height: 35px;
  width: 35px;
  font-weight: 500;
  background-color: #2f53eb;
  border-radius: 50%;
  display: inline-block;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.TextBankWorkflow {
  margin-inline-start: 1.3vw;
  margin-inline-end: 7.03vw;
  margin-top: 2px;
  margin-bottom: 3.64vw;
  font-weight: 500;
}
.workflowImg {
  width: 80%;
  margin-top: 2.61vw;
}
.flexRowWorkFlow {
  display: flex;
  flex-direction: row;
}
.TexFlexColumn-MobileView {
  display: none;
}
@media (max-width: 768px) {
  .BankWorkflowSec {
    width: 75%;
  }

  .TextFlex {
    display: flex;
    flex-direction: column;
  }
  .TextFlexSubTitle {
    display: flex;
    flex-direction: column;
  }
  .TexFlexColumn {
    display: none;
  }
  .TexFlexColumn-MobileView {
    display: flex;
    flex-direction: column;
  }
  .BankWorkflowSec h2 {
    font-weight: 700;
    font-size: 4.3vw;
    margin-bottom: 5.75vw;
    display: flex;
    flex-direction: column;
    color: #000000;
  }
  .BankWorkflowSec p {
    z-index: 10;
    font-size: 3.35vw;
    margin-bottom: 8.75vw;
    margin-inline-start: 1.2vw;
    margin-top: 10px;
    white-space: pre-line;
    color: rgba(65, 65, 65, 0.8);
    display: flex;
    flex-direction: column;
  }
  .workflowImg {
    width: 70%;
    margin-top: 2.61vw;
    align-items: center;
  }
  .dotNum {
    height: 30px;
    width: 30px;
    font-weight: 500;
    background-color: #2f53eb;
    border-radius: 50%;
    display: inline-block;
    color: #ffffff;
    line-height: 35px;
    text-align: center;
  }
  .TextBankWorkflow {
    width: 100%;
    font-weight: 500;
  }
}

.videoHero {
  position: relative;
}
.videoHero .hdLinks {
  display: block;
}
.videoHero .sdLinks {
  display: none;
}
.videoHero video {
  width: 100%;
}
.videoHero .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
}
.videoHero .videoHeroContent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@media (max-width: 768px) {
  .videoHero .hdLinks {
    display: none;
  }
  .videoHero .sdLinks {
    display: block;
  }
}
